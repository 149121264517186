import { FoamTree } from '@carrotsearch/foamtree';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { cloneDeep } from 'lodash';
import { FoamtreeService } from './foamtree.service';
import { IRiplResults } from './foamtree.interface';

@Component({
  selector: 'itq-foamtree',
  templateUrl: './foamtree.component.html',
  styleUrls: ['./foamtree.component.scss'],
})
export class FoamtreeComponent implements OnInit, OnChanges, OnDestroy {
  @Input() dataSource: IRiplResults;

  @Output() updateRef = new EventEmitter<FoamTree>();
  @Output() drilldown = new EventEmitter();

  private foamtree: FoamTree;
  public documents: any;
  private preventDefault = false;
  readonly MIN_NUMBER_ITEMS = 50;

  constructor(private foamtreeService: FoamtreeService) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    if (this.foamtree) {
      this.foamtree?.dispose();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.dataSource?.currentValue?.clusters?.length > 0) {
      setTimeout(() => {
        this.documents = changes?.dataSource?.currentValue?.source_documents;
        const dataSource = this.foamtreeService.convert(
          cloneDeep(changes?.dataSource?.currentValue?.clusters),
        );
        this.initialize(dataSource);
      }, 100);
    } else {
    }
  }

  initialize(dataSource: any): void {
    if (this.foamtree) {
      this.foamtree?.dispose();
    }
    this.foamtree = new FoamTree({
      id: 'visualization',
      pixelRatio: window.devicePixelRatio || 1,
      pullbackDuration: 0,
      rolloutDuration: 0,
    });

    this.updateRef.emit(this.foamtree);

    window.removeEventListener('orientationchange', this.foamtree.resize);
    window.addEventListener('orientationchange', this.foamtree.resize);
    window.removeEventListener('resize', this.resize);
    window.addEventListener('resize', () => {
      this.resize(this.foamtree);
    });
    this.foamtree.set({
      dataObject: {
        groups: dataSource,
      },
      rainbowStartColor: '#95fa9b',
      rainbowEndColor: '#05f912',
      onGroupDoubleClick: (event: any) => {
        this.preventDefault = true;
        if (event && event.group) {
          this.drilldown.emit(event.group);
        }
      },
      onGroupClick: (event: any) => {
        setTimeout(() => {
          if (!this.preventDefault && event.group) {
            this.drilldown.emit(event.group);
          }
          this.preventDefault = false;
        }, 200);
      },
    });
  }

  resize(foamtree: FoamTree): () => void {
    let timeout: number;
    return () => {
      window.clearTimeout(timeout);
      timeout = window.setTimeout(foamtree.resize, 300);
    };
  }
}
