import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { IError } from '@intorqa-ui/core';
import { Observable, TimeoutError, throwError } from 'rxjs';
import { catchError, map, timeout } from 'rxjs/operators';
import { ApiConfigService } from './api-config-service.service';

@Injectable({
  providedIn: 'root',
})
export class ApiRequestService {
  constructor(
    @Inject(ApiConfigService) private config,
    private httpClient: HttpClient,
  ) {}

  get BASE_URI(): string {
    return this.config.apiBase;
  }

  get<T>(
    route: string,
    options: any = {},
    typeConverter: DTOTypeConverter<T>,
    mapFn?: (data: any) => void,
    apiVersion: string = 'v1.0',
  ): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      this.httpClient
        .get(`${this.config.apiBase}${apiVersion}${route}`, options)
        .pipe(
          timeout(this.config.apiRequestTimeout),
          map((data) => (mapFn ? mapFn(data) : data)),
          catchError((error) => {
            if (error instanceof TimeoutError) {
              reject(error);
              return throwError('Timeout Exception');
            }
            reject(error || 'Timeout Exception');
            return throwError(error || 'Timeout Exception');
          }),
        )
        .toPromise()
        .then((result) => {
          resolve(typeConverter.fromType(result));
        });
    });
  }

  post<T>(
    route: string,
    typeConverter: DTOTypeConverter<T>,
    body?: any,
    options: any = {},
    apiVersion: string = 'v1.0',
  ): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      this.httpClient
        .post(`${this.config.apiBase}${apiVersion}${route}`, body, options)
        .toPromise()
        .then((result) => {
          resolve(typeConverter.fromType(result));
        })
        .catch((error: IError) => {
          reject(error);
        });
    });
  }

  getToObservable<T>(
    route: string,
    typeConverter: DTOTypeConverter<T>,
    options: any = {},
    apiVersion: string = 'v1.0',
  ): Observable<T> {
    return this.httpClient
      .get(`${this.config.apiBase}${apiVersion}${route}`, options)
      .pipe(map((data) => typeConverter.fromType(data)));
  }

  postToObservable<T>(
    route: string,
    typeConverter: DTOTypeConverter<T>,
    body?: any,
    options: any = {},
    apiVersion: string = 'v1.0',
  ): Observable<T> {
    return this.httpClient
      .post(`${this.config.apiBase}${apiVersion}${route}`, body, options)
      .pipe(
        catchError((error) => {
          return throwError(error);
        }),
        map((data) => {
          return typeConverter.fromType(data);
        }),
      );
  }

  put<T>(
    route: string,
    typeConverter: DTOTypeConverter<T>,
    body?: any,
    options: any = {},
    apiVersion: string = 'v1.0',
  ): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      this.httpClient
        .put(`${this.config.apiBase}${apiVersion}${route}`, body, options)
        .toPromise()
        .then((result) => {
          resolve(typeConverter.fromType(result));
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  putToObservable<T>(
    route: string,
    body?: any,
    options: any = {},
    apiVersion: string = 'v1.0',
  ): Observable<any> {
    return this.httpClient.put(
      `${this.config.apiBase}${apiVersion}${route}`,
      body,
      options,
    );
  }

  delete(
    route: string,
    options: any = {},
    apiVersion: string = 'v1.0',
  ): Promise<any> {
    return this.httpClient
      .delete(`${this.config.apiBase}${apiVersion}${route}`, options)
      .toPromise();
  }

  deleteToObserable(
    route: string,
    data?: any,
    apiVersion: string = 'v1.0',
  ): Observable<any> {
    return this.httpClient.delete(
      `${this.config.apiBase}${apiVersion}${route}`,
      { body: data },
    );
  }
}

export class DTOTypeConverter<T> {
  public fromType(responseJson: any): T {
    return responseJson;
  }
}

export class DTOCreation {
  readonly id: string;
}

export class DTOMessage {
  readonly message: string;
}
