import { ISocialChannelNote } from '../interfaces/social-channel-note.interface';
import { ProfileNote } from './profile-note';

export class SocialChannelNote
  extends ProfileNote
  implements ISocialChannelNote
{
  constructor(
    public id: string,
    public profileId: string,
    public typeId: string,
    public documentId: string,
    public updatedDate: number,
    public updatedBy: string,
    public subType: string,
    public rawValue: string,
    public textNote: string,
    public rawTextNote: string,
    public ecosystemId: string,
  ) {
    super(
      id,
      profileId,
      typeId,
      documentId,
      updatedDate,
      updatedBy,
      ecosystemId,
    );
  }

  public isValid(): boolean {
    return this.typeId && this.subType && this.rawValue && this.textNote
      ? true
      : false;
  }
}
