<div class="flex relative flex-col flex-1">
  <itq-input
    class="p-4"
    [(ngModel)]="query"
    [icon]="'search'"
    [style]="'link'"
    [placeholder]="'Please type to filter...'"
  ></itq-input>
  <mat-list
    #selectionsList
    class="border-t border-border"
    (scroll)="onScroll()"
  >
    @for (item of dataSource; track item[dataFields.value]) {
      <mat-list-item class="cursor-pointer hover:bg-gray-200 items-center">
        <div class="flex justify-between items-center">
          <div class="flex items-center">
            @if (item.icon) {
              <itq-icon
                [iconName]="item.icon"
                [iconType]="item.iconType"
              ></itq-icon>
            }
            <span>
              {{ dataFields ? item[dataFields.name] : item }}
            </span>
          </div>
          <ng-container
            *ngTemplateOutlet="template; context: { $implicit: item }"
          ></ng-container>
        </div>
      </mat-list-item>
    }
  </mat-list>

  @if (dataSource && dataSource.length === 0) {
    <itq-panel-info
      [label]="'No items found!'"
      [message]="'Please update your query...'"
      [icon]="'exclamation-circle'"
    ></itq-panel-info>
  }
</div>
