<div
  class="item-detail-container p-4 pl-0 cursor-pointer w-full"
  #itemdetailcontainer
  (click)="onDrilldown(SegmentScope.ARTICLE_DETAIL)"
>
  @if (allowDrilldown) {
    <div class="actions__container">
      @if (keycloakService.isUserInRole('saas-profiles')) {
        <itq-mat-button
          class="mr-1"
          [type]="'button'"
          [size]="Sizes.SM"
          [padding]="Sizes.SM"
          [materialStyle]="'stroked'"
          matTooltip="Add note"
          [matTooltipPosition]="'below'"
          (clickEvent)="onAddNote($event)"
        >
          <fa-icon [icon]="['far', 'note']"></fa-icon>
        </itq-mat-button>
        <itq-mat-button
          class="mr-1"
          *ngIf="profile"
          [type]="'button'"
          [size]="Sizes.SM"
          [padding]="Sizes.SM"
          [materialStyle]="'stroked'"
          matTooltip="Add connection"
          [matTooltipPosition]="'below'"
          (clickEvent)="onAddConnection($event)"
        >
          <fa-icon [icon]="['far', 'code-merge']"></fa-icon>
        </itq-mat-button>
      }

      <itq-mat-button
        class="mr-1"
        [type]="'button'"
        [size]="Sizes.SM"
        [padding]="Sizes.SM"
        [materialStyle]="'stroked'"
        (clickEvent)="shareSlack($event)"
        matTooltip="Share to slack"
        [matTooltipPosition]="'below'"
      >
        <fa-icon [icon]="['fab', 'slack']"></fa-icon>
      </itq-mat-button>
      <itq-mat-button
        class="mr-1"
        [type]="'button'"
        [size]="Sizes.SM"
        [padding]="Sizes.SM"
        [materialStyle]="'stroked'"
        (click)="onShareEmail($event, item)"
        matTooltip="Share by email"
        [matTooltipPosition]="'below'"
      >
        <fa-icon [icon]="['far', 'envelope']"></fa-icon>
      </itq-mat-button>
      @if (repliesCount > 0 && showReplies) {
        <itq-pill
          class="rounded-md"
          (select)="onDrilldown(SegmentScope.REPLIES)"
        >
          {{
            repliesCount > 1
              ? repliesCount + ' replies'
              : repliesCount + ' reply'
          }}
        </itq-pill>
      }
    </div>
  }
  <div class="container__header pb-4">
    <div class="header_icon rounded-md bg-black">
      <img [src]="'assets/icons/' + item?.emitSource?.toLowerCase() + '.svg'" />
    </div>
    <div class="header__content">
      <div class="items-center flex">
        @if (allowDrilldown) {
          @if (item.emitChannel) {
            <span
              class="text-link font-semibold cursor-pointer"
              (click)="
                $event.stopImmediatePropagation();
                onDrilldown(SegmentScope.CHANNEL)
              "
            >
              <span
                [innerHTML]="
                  item.emitChannel | highlightSearch: query?.toString()
                "
              ></span>
            </span>
          }
          @if (keycloakService.isUserInRole('saas-profiles')) {
            <itq-nav-button
              class="ml-2"
              [padding]="Sizes.NONE"
              [openDirection]="{ vertical: 'down', horizontal: 'right' }"
              [openPosition]="'inside'"
              [size]="Sizes['X-SM']"
              [width]="200"
              [template]="profileTemplate"
              [templateData]="ProfileDrildownScope.CHANNEL"
              [materialStyle]="'stroked'"
            >
              <fa-icon [icon]="['far', 'ellipsis-h']"></fa-icon>
            </itq-nav-button>
          }
        } @else {
          @if (item.emitChannel) {
            <span
              class="font-semibold"
              [innerHTML]="
                item.emitChannel | highlightSearch: query?.toString()
              "
            ></span>
          }
        }
      </div>
      <div class="content_title text-xs">
        <span
          [innerHTML]="item.emitSource | highlightSearch: query?.toString()"
        ></span>
        <span
          [innerHTML]="item.emitType | highlightSearch: query?.toString()"
        ></span>
      </div>
    </div>
  </div>
  <div class="container__content rounded-md p-4">
    <div class="container__content_wrapper">
      <div
        class="content-headline font-semibold"
        [innerHTML]="item.emitHeadline | highlightSearch: query?.toString()"
      ></div>
      <div class="subheadline-when text-xs py-2">
        {{ getDocumentDate(this.item.emitDocumentDate) | timeAgo
        }}<span>&nbsp;by&nbsp;</span>
        @if (allowDrilldown) {
          <div
            class="text-link font-semibold cursor-pointer"
            (click)="
              $event.stopImmediatePropagation(); onDrilldown(SegmentScope.ACTOR)
            "
          >
            <span
              [innerHTML]="item.emitActor | highlightSearch: query?.toString()"
            ></span>
          </div>
          @if (keycloakService.isUserInRole('saas-profiles')) {
            <itq-nav-button
              class="ml-2"
              [padding]="Sizes.NONE"
              [openDirection]="{ vertical: 'down', horizontal: 'right' }"
              [openPosition]="'inside'"
              [size]="Sizes['X-SM']"
              [width]="200"
              [template]="profileTemplate"
              [templateData]="ProfileDrildownScope.ACTOR"
              [materialStyle]="'stroked'"
            >
              <fa-icon [icon]="['far', 'ellipsis-h']"></fa-icon>
            </itq-nav-button>
          }
        } @else {
          <div>
            <span
              class="font-semibold"
              [innerHTML]="item.emitActor | highlightSearch: query?.toString()"
            ></span>
          </div>
        }
      </div>
      <p
        class="content-body text-xs"
        [innerHTML]="
          item.emitBodyText
            | striphtml
            | stripImg
            | highlightSearch: query?.toString()
        "
      ></p>
    </div>
    <itq-mat-button
      *ngIf="showReplies"
      [type]="'button'"
      [materialStyle]="'mini-fab'"
      matTooltip="View full document"
      [matTooltipPosition]="'below'"
      (clickEvent)="expanded = !expanded"
    >
      <fa-icon
        [icon]="['far', expanded ? 'chevrons-up' : 'chevrons-down']"
      ></fa-icon>
    </itq-mat-button>
  </div>
</div>
<ng-template #profileTemplate let-data>
  <div
    class="menu__container flex"
    (mouseleave)="showNestedNavbar = false; showListProfile = false"
  >
    <div class="menu__wrapper">
      <itq-mat-button
        [size]="Sizes.BLOCK"
        [type]="'button'"
        [align]="Align.START"
        [materialStyle]="'basic'"
        [padding]="Sizes['X-SM']"
        (mouseover)="showNestedNavbar = true; showListProfile = false"
      >
        <div class="flex justify-between">
          Create new profile
          <fa-icon
            [icon]="[
              'far',
              showNestedNavbar ? 'chevron-right' : 'chevron-down'
            ]"
          ></fa-icon>
        </div>
      </itq-mat-button>
      <itq-mat-button
        [size]="Sizes.BLOCK"
        [type]="'button'"
        [align]="Align.START"
        [materialStyle]="'basic'"
        [padding]="Sizes['X-SM']"
        (mouseover)="
          onMouseOverLinkProfile(
            data === ProfileDrildownScope.ACTOR
              ? TagCategory.Actor
              : TagCategory.Channel
          )
        "
      >
        <div class="flex justify-between">
          Add existing profile
          <fa-icon
            [icon]="['far', showListProfile ? 'chevron-right' : 'chevron-down']"
          ></fa-icon>
        </div>
      </itq-mat-button>
      <itq-mat-button
        [size]="Sizes.BLOCK"
        [align]="Align.START"
        [type]="'button'"
        [materialStyle]="'basic'"
        [padding]="Sizes['X-SM']"
        (mouseover)="showListProfile = false; showNestedNavbar = false"
        (clickEvent)="
          onDrilldown(
            data === ProfileDrildownScope.ACTOR
              ? SegmentScope.ACTOR
              : SegmentScope.CHANNEL
          )
        "
      >
        Explore {{ data === ProfileDrildownScope.ACTOR ? 'Actor' : 'Channel' }}
      </itq-mat-button>
    </div>

    <div class="menu__sidebar" *ngIf="showListProfile">
      <itq-dropdown-results
        [dataSource]="profilesDataSource"
        [httpBinding]="true"
        [dataFields]="{ name: 'name', value: 'profileId' }"
        [initialState]="profileInitialState"
        (dataBound)="onProfileDataBound($event)"
        (changeValue)="
          onLinkProfile(
            $event,
            data === ProfileDrildownScope.ACTOR
              ? ProfileDrildownScope.ACTOR
              : ProfileDrildownScope.CHANNEL
          )
        "
      ></itq-dropdown-results>
    </div>
    <div
      class="menu__sidebar"
      [ngStyle]="{ width: '170px' }"
      *ngIf="showNestedNavbar"
    >
      <itq-mat-button
        *ngFor="let item of profileTypesDataSource"
        [size]="Sizes.BLOCK"
        [align]="Align.START"
        [type]="'button'"
        [materialStyle]="'basic'"
        [padding]="Sizes['X-SM']"
        (clickEvent)="
          onCreateProfile(
            data === ProfileDrildownScope.ACTOR
              ? ProfileDrildownScope.ACTOR
              : ProfileDrildownScope.CHANNEL,
            item
          )
        "
      >
        <fa-icon [icon]="['far', item?.name | getProfileTypeIcon]"></fa-icon>
        <span>
          {{ item?.name }}
        </span>
      </itq-mat-button>
    </div>
  </div>
</ng-template>
