import { EChartsOption } from 'echarts';
import { Widget } from './widget';
import { Chart } from './chart';
import { Utils, defaultTheme } from '@intorqa-ui/core';
import { ChartType, LineMode } from '@portal/shared/enums/widget.enum';
import {
  ISerie,
  IDataPoint,
  IDisplayType,
} from '@portal/shared/interfaces/widget.interface';

export class LineChart extends Chart {
  constructor(public name: ChartType) {
    super(name);
  }

  public getOptions(
    data: Array<ISerie>,
    segment: any,
    settings: { [key: string]: any },
  ): EChartsOption {
    return {
      tooltip: {
        show: settings.tooltip?.show !== false ? true : false,
        trigger: data?.length > 1 ? 'item' : 'axis',
        extraCssText: 'overflow: auto; max-height: 250px; border: 0;',
        enterable: true,
        axisPointer: {
          type: 'shadow',
        },
      },
      textStyle: {
        fontFamily: 'Mont', // Set the default font family here
        color: 'black', // Set the default color here
      },
      xAxis: {
        show: settings?.xAxis?.show !== false ? true : false,
        data: data[0]?.data.map((item: IDataPoint) => {
          return item.category;
        }),
        type: 'category',
        axisTick: {
          alignWithLabel: true,
        },
        axisLabel: {
          rotate: -320,
          formatter: (value: string) => {
            return value.trim().replace('\n', '').length > 10
              ? value.trim().replace('\n', '').substring(0, 10) + '...'
              : value.trim().replace('\n', '');
          },
        },
      },
      yAxis: {
        show: settings?.yAxis?.show !== false ? true : false,
        type: 'value',
        axisLabel: {
          formatter: (value: number) => {
            return Utils.numFormatter(value);
          },
        },
      },
      series: data.map((serie: ISerie, index: number) => {
        return {
          name: serie.name,
          data: serie.data.map((item: IDataPoint) => {
            return this.getDataPoint(item, segment, serie.tagId, index);
          }),
          type: 'line',
          symbol: 'circle',
          showSymbol: settings?.series?.showSymbol !== false ? true : false,
          symbolSize: settings?.series?.symbolSize || 10,
          lineStyle: {
            width: settings?.series?.lineStyle?.width || 1,
          },
          label: {
            show:
              settings?.series?.label?.show === true ||
              settings?.series?.label?.show === undefined
                ? data?.length > 1 || serie.data?.length > 15
                  ? false
                  : true
                : false,
            position: 'top',
            labelLayout: {
              hideOverlap: true,
            },
            formatter: (params: any) => {
              return params.value === 0 ? '' : params.value;
            },
          },
          tooltip: {
            confine: false,
            formatter: (value: any) => {
              let result = '<div>' + value.name + '</div>';
              let resultObj = data.map((item: ISerie, index: number) => {
                const overedSeries = item.name === value.seriesName;
                let tooltiptipItem = '';
                const dataPointValue = item.data.find(
                  (dataPoint: IDataPoint) => dataPoint.category === value.name,
                );
                if (overedSeries) {
                  tooltiptipItem +=
                    "<span class='display: block;  width: 150px; height: 30px; padding-top: 5px;border: 1px solid black;'>";
                  tooltiptipItem +=
                    "<span style='padding-left: 5px;padding-right: 5px;display: inline-block;width: 10px; height: 10px; background-color: " +
                    defaultTheme.theme.color[index] +
                    "; border-radius: 100%;'></span>";
                  tooltiptipItem +=
                    "<div style='display: block;background-color: #8f8f90; border-radius: 5px; justify-content: space-between; padding-left: 5px;font-weight: bold; display: inline-block; color: " +
                    defaultTheme.theme.color[index] +
                    "'><span>" +
                    item.name +
                    "</span> - <span style='color: #333; padding-right: 5px;'>" +
                    dataPointValue.count +
                    '</span></div>';
                  tooltiptipItem += '</span>';
                } else {
                  tooltiptipItem +=
                    "<div class='display: flex; justify-content: space-between; width: 150px; height: 30px; padding-top: 5px;'>";
                  tooltiptipItem +=
                    "<span style='display: inline-block;width: 10px; height: 10px; background-color: " +
                    defaultTheme.theme.color[index] +
                    "; border-radius: 100%;'></span>";
                  tooltiptipItem +=
                    "<span style='padding-left: 5px; display: inline-block; color: " +
                    defaultTheme.theme.color[index] +
                    "'>" +
                    item.name +
                    "</span> - <span style='font-weight: bold; color: #333;'>" +
                    dataPointValue.count +
                    '</span>';
                  tooltiptipItem += '</div>';
                }
                return {
                  value: dataPointValue,
                  text: tooltiptipItem,
                };
              });
              resultObj = resultObj.sort((a, b) => {
                return b.value.count > a.value.count ? 1 : -1;
              });
              return result + resultObj.map((item) => item.text).join('');
            },
          },
          smooth: settings?.series?.smooth !== false ? true : false,
        };
      }),
      grid: {
        left: settings?.yAxis?.show === false ? 0 : 50,
        top: data.length > 1 ? 40 : 10,
        right: 0,
        bottom:
          settings?.xAxis?.show === false || settings?.dataZoom?.show === false
            ? 0
            : data[0].data.length > 10
              ? 90
              : 60,
      },
      dataZoom: [
        {
          show:
            settings?.xAxis?.show === false
              ? false
              : data[0].data.length > 10
                ? true
                : false,
          realtime: true,
          start: 0,
          end: 100,
          height: 20,
          bottom: 10,
        },
      ],
      legend: {
        show: data.length > 1,
        type: 'scroll',
        icon: 'rect',
        top: 'top',
        orient: 'horizontal',
        left: 'center',
        formatter: (value: string) => {
          return value.trim().replace('\n', '').length > 30
            ? value.trim().replace('\n', '').substring(0, 30) + '...'
            : value.trim().replace('\n', '');
        },
      },
    };
  }

  public getSelectedType(
    displayTypes: Array<IDisplayType>,
    widget: Widget,
  ): IDisplayType {
    return displayTypes.find((item: IDisplayType) => {
      return (
        item.type === ChartType.LINE && item.options?.mode === LineMode.LINE
      );
    });
  }
}
