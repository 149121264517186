import { Injectable } from '@angular/core';
import { ApiRequestService, DTOTypeConverter } from '@intorqa-ui/api';
import { ITag, ITagTreeNode } from '../interfaces/tag.interface';

import { FormGroup } from '@angular/forms';
import {
  Category,
  DTOQuery,
  DateQueryType,
  DateRange,
  IError,
  QueryFilters,
  TagCategory,
} from '@intorqa-ui/core';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { FilterType } from '../enums/tag.enum';
import {
  IContext,
  IContextSearchResults,
  IData,
  ISearchResults,
} from '../interfaces/document.interface';
import { ITagMetadata } from '../interfaces/tag.interface';
import { Query } from '../models/query-model';
import { Tag } from '../models/tag';

interface ISaveTagDTO {
  name: string;
  description: string;
  categoryId: string;
  ecosystemId: string;
  query: DTOQuery;
  sharedTag: boolean;
  _extras: string;
}

@Injectable({
  providedIn: 'root',
})
export class TagService {
  public getFilters$ = new Subject<void>();
  public reloadFilters$ = new Subject<{
    queryFilters: QueryFilters;
    query: Query;
  }>();
  public resetQuery$ = new Subject<void>();
  public reloadTags$ = new Subject<void>();
  public toggleSharedTag$ = new Subject<boolean>();
  public tags$ = new Subject<{
    items: Array<Tag>;
    page: number;
    pageSize: number;
    totalCount: number;
  }>();

  public changeAlertType$ = new Subject<string>();
  public queryModel: Query;
  public getSearchResults$ = new Subject<void>();

  constructor(private apiRequestService: ApiRequestService) {}

  save(tag: ISaveTagDTO): Observable<Tag> {
    return this.apiRequestService
      .postToObservable(
        '/tags',
        new DTOTypeConverter<ITag>(),
        tag,
        undefined,
        'v2.0',
      )
      .pipe(
        catchError((error) => {
          return throwError(error);
        }),
        map((response: ITag) => {
          const extras = new Query();
          extras.query = extras.dtoToModel(response.query);
          extras.type = JSON.parse(response._extras)?.type;
          this.reloadTags$.next();
          return new Tag(
            response.tagId,
            response.description,
            response.createdDate,
            response.updatedDate,
            extras,
            response.name,
            response.query,
            response.sharedTag,
            response.username,
            response.categoryId,
            response.lastTaggingTime,
            response.alertTypeId,
            response.ecosystemId,
          );
        }),
      );
  }

  update(attributes: { [key: string]: any }): Observable<Tag> {
    return this.apiRequestService.putToObservable(
      `/tags/${attributes.tagId}`,
      attributes,
      undefined,
      'v2.0',
    );
  }

  getTags(
    initialState: QueryFilters,
    form: FormGroup,
    ecosystemId: string,
    type: FilterType,
  ): Observable<{
    items: Array<Tag>;
    page: number;
    pageSize: number;
    totalCount: number;
  }> {
    let pageQuery = `page=${initialState.page}`;
    pageQuery += `&size=${initialState.pageSize}`;
    if (form.controls.searchTerm.value) {
      pageQuery += `&search=${form.controls.searchTerm.value}`;
    }
    pageQuery += `&filter=${type}`;
    pageQuery += `&sortField=${initialState.sort?.active}`;
    pageQuery += `&sortOrder=${initialState.sort?.direction}`;
    pageQuery += `&ecosystemId=${ecosystemId}`;

    return this.apiRequestService
      .getToObservable(
        `/tags?${pageQuery}`,
        new DTOTypeConverter<{
          items: Array<Tag>;
          page: number;
          pageSize: number;
        }>(),
        undefined,
        'v2.0',
      )
      .pipe(
        map(
          (response: {
            items: Array<ITag>;
            page: number;
            pageSize: number;
            totalCount: number;
          }) => {
            const result = {
              items: response?.items.map((item: ITag) => {
                const extras = new Query();
                extras.query = extras.dtoToModel(item.query);
                extras.type = JSON.parse(item._extras)?.type;
                return new Tag(
                  item.tagId,
                  item.description,
                  item.createdDate,
                  item.updatedDate,
                  extras,
                  item.name,
                  item.query,
                  item.sharedTag,
                  item.username,
                  item.categoryId,
                  item.lastTaggingTime,
                  item.alertTypeId,
                  item.ecosystemId,
                );
              }),
              page: response.page,
              pageSize: response.pageSize,
              totalCount: response.totalCount,
            };
            return result;
          },
        ),
      );
  }

  public getTagById(id: string): Observable<Tag> {
    return this.apiRequestService.getToObservable(
      `/tags/${id}`,
      new DTOTypeConverter<Tag>(),
      undefined,
      'v2.0',
    );
  }

  delete(id: string): Promise<{ message: string }> {
    return this.apiRequestService.delete(`/tags/${id}`);
  }

  public getTagsMetadata(ids: Array<string>): Promise<Array<ITagMetadata>> {
    return this.apiRequestService.post(
      '/tags/metadata',
      new DTOTypeConverter<Array<ITagMetadata>>(),
      {
        tagIds: ids,
      },
      undefined,
      'v1.0',
    );
  }

  public getSelections(
    tagIds: { included: Array<string>; excluded: Array<string> },
    fieldFilterIds: { included: Array<string>; excluded: Array<string> },
    contentIds: { included: Array<string>; excluded: Array<string> },
    categories: Array<Category>,
  ): Promise<Array<ITagMetadata>> {
    return new Promise((resolve) => {
      let result: Array<ITagMetadata> = [];
      if (contentIds) {
        let contentFieldFilterIds = [
          ...contentIds?.included,
          ...contentIds?.excluded,
        ];
        contentFieldFilterIds.map((item: string) => {
          result = [
            ...result,
            {
              section: undefined,
              categoryName: TagCategory.content,
              tagName: undefined,
              tagId: item,
              included: contentIds.included.includes(item),
              excluded: contentIds.excluded.includes(item),
            },
          ];
        });
      }
      let allFieldFilterIds = [
        ...fieldFilterIds?.included,
        ...fieldFilterIds?.excluded,
      ];
      allFieldFilterIds.map((item: string) => {
        const splittedValue = item.split(':');
        result = [
          ...result,
          {
            section: categories.find(
              (item: Category) => item.name === splittedValue[2],
            )?.section,
            categoryName: splittedValue[2] as TagCategory,
            tagName: splittedValue[1],
            tagId: item,
            included: fieldFilterIds.included.includes(item),
            excluded: fieldFilterIds.excluded.includes(item),
          },
        ];
      });
      let mergedTagIds = [...tagIds?.included, ...tagIds?.excluded];
      if (mergedTagIds?.length > 0) {
        this.getTagsMetadata(mergedTagIds).then(
          (response: Array<ITagMetadata>) => {
            response.forEach((elem: ITagMetadata) => {
              const metadata = response.find((item: ITagMetadata) => {
                return elem.tagId === item.tagId;
              });
              result = [
                ...result,
                {
                  section: metadata.section,
                  categoryName: metadata.categoryName,
                  tagName: metadata.tagName,
                  tagId: metadata.tagId,
                  included: tagIds.included.includes(metadata.tagId),
                  excluded: tagIds.excluded.includes(metadata.tagId),
                },
              ];
            });
            resolve(result);
          },
        );
      } else {
        resolve(result);
      }
    });
  }

  public getApiKeyPrefix(id: string): Promise<string> {
    return new Promise((resolve, reject) => {
      this.apiRequestService
        .get(
          `/tags/${id}/apiKeyPrefix`,
          {},
          new DTOTypeConverter<string>(),
          undefined,
          'v2.0',
        )
        .then((response: string) => {
          resolve(response);
        })
        .catch((error: IError) => {
          reject(error);
        });
    });
  }

  public getDependants(tagId: string): Observable<ITagTreeNode> {
    return this.apiRequestService.getToObservable(
      `/tags/${tagId}/dependants`,
      new DTOTypeConverter<ITagTreeNode>(),
      'v1.0',
    );
  }

  public getDependencies(tagId: string): Observable<ITagTreeNode> {
    return this.apiRequestService.getToObservable(
      `/tags/${tagId}/dependencies`,
      new DTOTypeConverter<ITagTreeNode>(),
      'v1.0',
    );
  }

  public unlinkTag(
    unlinkId: string,
    dependencyId: string,
  ): Observable<ITagTreeNode> {
    return this.apiRequestService.putToObservable(
      `/tags/${unlinkId}/unlink/${dependencyId}`,
      new DTOTypeConverter<ITagTreeNode>(),
      'v1.0',
    );
  }
  public execute(
    state: QueryFilters,
    queryModel: Query,
    ecosystemId: string,
  ): Promise<IData> {
    return new Promise((resolve) => {
      const query = queryModel.modelToDTO();
      const action = state?.page || 1;
      let pageQuery = `page=${action}`;

      if (state?.pageSize) {
        pageQuery += `&size=${state.pageSize}`;
      }
      if (state?.where) {
        if (state.where.label === DateQueryType.Custom) {
          pageQuery += `&dateFrom=${state.where?.start}`;
          pageQuery += `&dateTo=${state.where?.end}`;
        } else {
          let preset = DateRange.findPresetByLabel(state.where.label);
          pageQuery += `&dateFrom=${DateRange.convertToEpochSec(preset?.start.toDate())}`;
          pageQuery += `&dateTo=${DateRange.convertToEpochSec(preset?.end.toDate())}`;
        }
      }
      if (ecosystemId) {
        pageQuery += `&ecosystemId=${ecosystemId}`;
      }
      return this.apiRequestService
        .post(
          `/tags/search?${pageQuery}`,
          new DTOTypeConverter<ISearchResults>(),
          JSON.stringify(query),
        )
        .then((response: ISearchResults) => {
          const data = {
            result: response?.items,
            count: response?.totalHits,
          } as IData;
          resolve(data);
        });
    });
  }

  public getContext(
    state: QueryFilters,
    queryModel: Query,
    ecosystemId: string,
    context: IContext,
  ): Promise<IData> {
    return new Promise((resolve) => {
      const query = queryModel.modelToDTO();
      let pageQuery = `size=${state.pageSize}`;

      if (state?.where) {
        if (state.where.label === DateQueryType.Custom) {
          pageQuery += `&dateFrom=${state.where?.start}`;
          pageQuery += `&dateTo=${state.where?.end}`;
        } else {
          let preset = DateRange.findPresetByLabel(state.where.label);
          pageQuery += `&dateFrom=${DateRange.convertToEpochSec(preset?.start.toDate())}`;
          pageQuery += `&dateTo=${DateRange.convertToEpochSec(preset?.end.toDate())}`;
        }
      }
      if (ecosystemId) {
        pageQuery += `&ecosystemId=${ecosystemId}`;
      }
      if (context) {
        pageQuery += `&context=${context.document.id}`;
        if (context.before || context.after) {
          pageQuery += `&page=${state.page}`;
        }
        if (context.before) {
          pageQuery += `&before=${context.before}`;
        }
        if (context.after) {
          pageQuery += `&after=${context.after}`;
        }
      }

      return this.apiRequestService
        .post(
          `/tags/search?${pageQuery}`,
          new DTOTypeConverter<ISearchResults>(),
          JSON.stringify(query),
        )
        .then((response: IContextSearchResults) => {
          resolve({
            count: response.totalHits,
            result: response.items,
            page: response.page,
          });
        });
    });
  }

  public getTagsForUser(ecosystemId: string): Observable<Array<ITag>> {
    return this.apiRequestService.getToObservable(
      `/tags/user?ecosystemId=${ecosystemId}`,
      new DTOTypeConverter<Array<ITag>>(),
      undefined,
      'v2.0',
    );
  }
}
