<itq-mat-button
  #button
  [size]="Sizes.BLOCK"
  [ngClass]="{ invalid }"
  [type]="'button'"
  [padding]="padding"
  [style]="color"
  [disabled]="disabled"
  [materialStyle]="materialStyle"
  (click)="$event.stopImmediatePropagation()"
  (clickEvent)="onClick()"
>
  <div class="flex w-full items-center justify-between h-full">
    <div class="wrapper_text font-medium">
      @if (httpBinding) {
        {{ (selection && selection[dataFields?.name]) || placeholder }}
      } @else {
        {{ dataSource | getLabel: dataFields : selection : placeholder }}
      }
    </div>
    <div class="flex items-center h-full">
      @if (showLoader) {
        <itq-loader [type]="'controls'"></itq-loader>
      }
      @if (clearSelection && selection) {
        <fa-icon
          [icon]="['far', 'times']"
          [size]="'lg'"
          (mousedown)="onClear($event)"
        ></fa-icon>
      }
      <fa-icon
        class="border-l border-border h-full pl-1 ml-2"
        [icon]="isOpen ? ['far', 'chevron-up'] : ['far', 'chevron-down']"
      ></fa-icon>
    </div>
  </div>
</itq-mat-button>
<ng-template #portalContent>
  <div
    [ngStyle]="{ 'width.px': dropdownWidth }"
    [ngClass]="['navigation__container', direction]"
  >
    <div *ngIf="template && open; else resultsTemplate">
      <ng-container *ngTemplateOutlet="template"></ng-container>
    </div>
    <ng-template #resultsTemplate>
      @if (dataSource) {
        <itq-dropdown-results
          [emptyDataSourceMessage]="emptyDataSourceMessage"
          [showHeader]="showHeader"
          [direction]="direction"
          [dataSource]="dataSource"
          [httpBinding]="httpBinding"
          [selection]="selection"
          [dataFields]="dataFields"
          [initialState]="initialState"
          [selection]="selection"
          (dataBound)="onDataBound($event)"
          (changeValue)="onChangeValue($event)"
        ></itq-dropdown-results>
      }
    </ng-template>
  </div>
</ng-template>
