import {
  Component,
  ComponentFactoryResolver,
  HostBinding,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';

import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { DynamicPlaceholderDirective } from '../../directives/dynamic-placeholder.directive';
import { SharedService } from '../../services/shared.service';
import { CustomOverlayConfig } from './custom-overlay.interface';
import { CustomOverlayRef } from './custom-overlay.ref';
import { CUSTOM_OVERLAY_DATA } from './custom-overlay.tokens';

@Component({
  selector: 'itq-custom-overlay',
  templateUrl: './custom-overlay.component.html',
  styleUrls: ['./custom-overlay.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomOverlayComponent implements OnInit, OnDestroy {
  @ViewChild(DynamicPlaceholderDirective, { static: true })
  placeholder: DynamicPlaceholderDirective;

  private subscription: Subscription;

  @HostBinding('class') componentClass: string;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    @Inject(CUSTOM_OVERLAY_DATA) public config: CustomOverlayConfig,
    public customOverlayRef: CustomOverlayRef,
    public router: Router,
    public sharedService: SharedService,
  ) {
    if (this.config.disposeOnNavigation) {
      this.subscription = this.router.events
        .pipe(filter((event) => event instanceof NavigationEnd))
        .subscribe(() => {
          this.onClose();
        });
    }
  }

  ngOnInit(): void {
    this.componentClass = this.config.cssClass;
    this.createComponent();
    if (this.config.cssClass) {
      document.body.classList.add('cdk-overlay-open-' + this.config.cssClass);
    }
  }

  private createComponent(): void {
    const componentFactory =
      this.componentFactoryResolver.resolveComponentFactory(
        this.config.component,
      );
    const viewContainerRef = this.placeholder.viewContainerRef;
    viewContainerRef.clear();
    const componentRef = viewContainerRef.createComponent(componentFactory);
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  onClose(): void {
    this.customOverlayRef.close({ refresh: false });
  }
}
