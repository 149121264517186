import { ProfileMetadata } from '@portal/profiles/models/profile-metadata';
import { AnalysisTypes, ChartType } from '../../shared/enums/widget.enum';
import { Widget } from '../../boards/models/widget';
import { IProfile } from '@portal/profiles/interfaces/profile.interface';

export class Profile extends Widget implements IProfile {
  constructor(
    public widgetId: string,
    public username: string,
    public type: AnalysisTypes,
    public name: string,
    public description: string,
    public chartType: ChartType,
    public ecosystemId: string,
    public profileTypeId: string,
    public profileTypeName: string,
    public profileId: string,
    public metadata: Array<ProfileMetadata>,
    public trend: number,
    public activity: number,
    public updatedBy: string,
    public updatedDate: number,
  ) {
    super(
      widgetId,
      username,
      type,
      name,
      description,
      chartType,
      ecosystemId,
      updatedDate,
    );
  }

  public cloneDeep(): Profile {
    return new Profile(
      this.widgetId,
      this.username,
      this.type,
      this.name,
      this.description,
      this.chartType,
      this.ecosystemId,
      this.profileTypeId,
      this.profileTypeName,
      this.profileId,
      this.metadata,
      this.trend,
      this.activity,
      this.updatedBy,
      this.updatedDate,
    );
  }
}
