import { TemplateRef } from '@angular/core';
import {
  DTOQueryFieldType,
  TableColumn,
  TagCategory,
  Utils,
} from '@intorqa-ui/core';
import { EChartsOption } from 'echarts';
import { cloneDeep } from 'lodash';
import {
  AnalysisTypes,
  ChartOrientation,
  ChartType,
  WidgetActions,
  WidgetOption,
} from '../../shared/enums/widget.enum';
import { ChartFactory } from '../factories/chart';
import {
  IDataPoint,
  IDisplayType,
  ISerie,
  ITagAnalysis,
} from '../../shared/interfaces/widget.interface';
import { Widget } from './widget';
import { TAG_ANALYSIS_CHART_TYPES } from '../const/tag-analysis.const';
import { TimeSeries } from './time-series';
import { TagComparison } from './tag-comparison';

export class TagAnalysis extends Widget implements ITagAnalysis {
  constructor(
    public widgetId: string,
    public username: string,
    public type: AnalysisTypes,
    public name: string,
    public description: string,
    public width: number,
    public height: number,
    public x: number,
    public y: number,
    public options: { [key: string]: any },
    public dataSource: Array<string>,
    public dataPoints: Array<TagCategory>,
    public top: number,
    public chartType: ChartType,
    public ecosystemId: string,
    public updatedDate: number,
  ) {
    super(
      widgetId,
      username,
      type,
      name,
      description,
      chartType,
      ecosystemId,
      updatedDate,
    );
  }

  public hasOptions(): boolean {
    return this.dataSource?.length > 0 &&
      this.top > 0 &&
      this.dataPoints?.length > 0 &&
      this.chartType
      ? true
      : false;
  }

  public hasMetrics(): boolean {
    return this.dataSource?.length > 0 || this.dataPoints?.length > 0
      ? true
      : false;
  }

  public resetMetrics(): void {
    this.dataSource = undefined;
    this.top = 10;
    this.dataPoints = undefined;
  }

  public generateGetDocsState(params: { [key: string]: any }): any {
    return {
      pageSize: 30,
      page: params.initialState?.page || 1,
      where: params.initialState?.where,
    };
  }

  public getDataPointField(): DTOQueryFieldType {
    if (
      this.dataPoints[0] === TagCategory.Channel ||
      this.dataPoints[0] === TagCategory.Actor ||
      this.dataPoints[0] === TagCategory.Source ||
      this.dataPoints[0] === TagCategory['Post Type'] ||
      this.dataPoints[0] === TagCategory.Community
    ) {
      return DTOQueryFieldType.filter;
    }
    return DTOQueryFieldType.tag;
  }

  public canLoadDocuments(action: WidgetActions): boolean {
    return action === WidgetActions.EXPLORE;
  }

  public transformData(series: Array<ISerie>): Array<ISerie> {
    series = series.filter((item: ISerie) => {
      return item.data.length > 0;
    });
    if (
      (this.chartType === ChartType.BAR &&
        this.options[WidgetOption.ORIENTATION] === ChartOrientation.VERTICAL) ||
      this.chartType === ChartType.TABLE
    ) {
      series.forEach((serie: ISerie) => {
        return serie.data.sort((a: IDataPoint, b: IDataPoint) =>
          a.count > b.count ? -1 : 1,
        );
      });
    }
    return series;
  }

  public getDataPointValue(segment: any): string {
    return segment?.data?.tagId;
  }

  public getTagId(): string {
    return this.dataSource[0];
  }

  public getTableColumns(template: TemplateRef<unknown>): Array<TableColumn> {
    return [
      {
        name: this.dataPoints[0],
        dataKey: 'category',
        width: 'fit',
        isSortable: true,
      },
      {
        name: 'Count',
        dataKey: 'count',
        position: 'right',
        width: '80px',
        customRender: true,
        template: template,
        isSortable: true,
      },
    ];
  }

  public getDisplayTypes(): Array<IDisplayType> {
    return TAG_ANALYSIS_CHART_TYPES;
  }

  public getSelectedType(): IDisplayType {
    if (TAG_ANALYSIS_CHART_TYPES?.length > 0) {
      const widget = cloneDeep(this);
      widget.chartType = this.chartType;
      const chart = ChartFactory.createObject(widget);
      return chart.getSelectedType(TAG_ANALYSIS_CHART_TYPES, this);
    }
  }

  public getRuleValue(): Array<string> {
    return [this.dataSource[0]];
  }

  public getTagIds(): Array<string> {
    return this.dataSource;
  }

  public getOptions(
    series: Array<ISerie>,
    action: WidgetActions,
    segment?: any,
  ): EChartsOption {
    let options: EChartsOption;
    const chart = ChartFactory.createObject(this);
    const mode = this.getOption(WidgetOption.MODE);
    const xAxis = this.getOption(WidgetOption.X_AXIS);
    const yAxis = this.getOption(WidgetOption.Y_AXIS);
    const dataZoom = this.getOption(WidgetOption.DATA_ZOOM) || { show: true };
    const seriesOptions = this.getOption(WidgetOption.SERIES);
    const tooltip = this.getOption(WidgetOption.TOOLTIP);
    options = chart.getOptions(series, segment, {
      widgetAction: action,
      width: this.width,
      mode,
      xAxis,
      yAxis,
      dataZoom,
      series: seriesOptions,
      tooltip,
    });
    return options;
  }

  public getOption(option: WidgetOption): any {
    let result: any;
    if (this.options) {
      Object.keys(this.options)?.forEach((key: WidgetOption) => {
        if (key === option) {
          result = this.options[key];
        }
      });
    }
    return result;
  }

  public cloneDeep(): TagAnalysis {
    return new TagAnalysis(
      this.widgetId,
      this.username,
      this.type,
      this.name,
      this.description,
      this.width,
      this.height,
      this.x,
      this.y,
      this.options,
      this.dataSource,
      this.dataPoints,
      this.top,
      this.chartType,
      this.ecosystemId,
      this.updatedDate,
    );
  }
}
