<div
  [ngClass]="[
    'flex',
    'items-center',
    'border',
    'border-solid',
    'border-border',
    'rounded',
    'bg-white',
    'date-range-container',
    class
  ]"
  (click)="$event.stopPropagation()"
  [formGroup]="form"
>
  <mat-date-range-input
    class="mx-2"
    [formGroup]="form | castFormGroup: 'date'"
    [rangePicker]="picker"
    [disabled]="disabled"
    [min]="minDate"
    [max]="maxDate"
  >
    <input
      matStartDate
      formControlName="start"
      placeholder="Start date"
      [errorStateMatcher]="customErrorStateMatcher"
    />
    <input matEndDate formControlName="end" placeholder="End date" />
  </mat-date-range-input>
  <itq-mat-button
    [ngStyle]="{ margin: '1px' }"
    [materialStyle]="'basic'"
    [size]="Sizes.SM"
    [padding]="Sizes.NONE"
    matSuffix
    [disabled]="disabled"
    [for]="picker"
    (clickEvent)="picker.open()"
  >
    <fa-icon [size]="'lg'" [icon]="['far', 'calendar']"></fa-icon>
  </itq-mat-button>
  @if (!required && form?.get('date')?.valid) {
    <itq-mat-button
      [materialStyle]="'basic'"
      [type]="'button'"
      [disabled]="disabled"
      [padding]="Sizes.NONE"
      (mouseDownEvent)="onClearDate()"
    >
      <fa-icon [icon]="['far', 'times']"></fa-icon>
    </itq-mat-button>
  }

  <mat-date-range-picker
    #picker
    [calendarHeaderComponent]="DateRangeHeaderComponent"
    (closed)="onApply(picker)"
  >
  </mat-date-range-picker>
</div>
