import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { CoreModule, defaultTheme } from '@intorqa-ui/core';
import { NetworkChart } from '@portal/boards/models/network-chart';
import { TagAnalysis } from '@portal/boards/models/tag-analysis';
import { TagComparison } from '@portal/boards/models/tag-comparison';
import { TimeSeries } from '@portal/boards/models/time-series';
import { Widget } from '@portal/boards/models/widget';
import { WidgetService } from '@portal/boards/services/widget.service';
import { WidgetActions } from '@portal/shared/enums/widget.enum';
import { Timeline } from '@portal/shared/models/timeline';
import { ISegment } from '@portal/widget-settings/interfaces/widget-settings.interface';
import { ECharts, EChartsOption } from 'echarts';
import * as echarts from 'echarts/core';
import { NgxEchartsDirective, provideEcharts } from 'ngx-echarts';
import { Subscription } from 'rxjs';

@Component({
  selector: 'itq-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
  standalone: true,
  imports: [CoreModule, NgxEchartsDirective],
  providers: [provideEcharts()],
})
export class ChartComponent {
  @Input() widget:
    | TagAnalysis
    | TimeSeries
    | TagComparison
    | Timeline
    | NetworkChart;
  @Input() action = WidgetActions.RENDER;
  @Input() data: any;
  @Input() segment: ISegment;
  @Input() noResultsTitle = 'No results found!';
  @Input() noResultsMessage = 'Please update your settings and try again...';

  @Output() drilldown = new EventEmitter<any>();
  @Output() updateRef = new EventEmitter<ECharts>();

  @ViewChild('chartContainer') chartContainer: ElementRef;

  public chartInstance: ECharts;
  public options: EChartsOption;
  public theme = defaultTheme.themeName;
  private subscriptions = new Subscription();

  constructor(readonly widgetService: WidgetService) {
    echarts.registerTheme(defaultTheme.themeName, defaultTheme.theme);
    this.subscriptions.add(
      this.widgetService.reloadChart$.subscribe((widget: Widget) => {
        if (
          this.widget instanceof NetworkChart ||
          !widget ||
          widget.widgetId === this.widget.widgetId
        ) {
          this.reloadOptions();
        }
      }),
    );
  }

  ngOnInit(): void {
    this.reloadOptions();
  }

  public onChartInit(instance: ECharts): void {
    this.chartInstance = instance;
    this.onUpdateRef();
  }

  ngOnDestroy(): void {
    this.chartInstance?.dispose();
    this.subscriptions.unsubscribe();
  }

  onUpdateRef(): void {
    this.updateRef.emit(this.chartInstance);
  }

  private reloadOptions(): void {
    if (this.data?.series[0]?.data?.length > 0) {
      this.initEcharts();
    }
  }

  private initEcharts(): void {
    if (!(this.widget instanceof Timeline)) {
      this.options = this.widget.getOptions(
        this.data?.series,
        this.action,
        this.segment,
      );
    }
  }

  public onChartClick(params: any): void {
    if (this.widget instanceof NetworkChart) {
      if (
        params.dataType === 'node' &&
        params?.data?.name !== this.widget.chartName
      ) {
        this.drilldown.emit(params);
      }
    } else {
      this.drilldown.emit(params);
    }
  }
}
