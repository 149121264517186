export enum DocumentMode {
  Detail = 'Detail',
  Tile = 'Tile',
  Timeline = 'Timeline',
}

export enum DocumentItemMediaType {
  Video = 'Video',
  Image = 'Image',
}

export enum DateFormats {
  default = 'EEEE, MMMM d, y, h:mm',
  short = 'dd/MM/yyyy @ hh:mm a',
}
