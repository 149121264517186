import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MatDateRangePicker } from '@angular/material/datepicker';
import moment, { Moment } from 'moment';
import { Subscription } from 'rxjs';
import { DateQueryType } from '../../enums/date-range.enum';
import { Sizes } from '../../enums/shared.enum';
import { IPreset } from '../../interfaces/date-range.interface';
import { IPresetQuery } from '../../interfaces/query-filters';
import { DateRange } from '../../models/date-range';
import { DateRangeService } from '../../services/date-range.service';
import { DateRangeHeaderComponent } from './components/date-range-header/date-range-header.component';
import { CustomErrorStateMacther } from './date-range-error-matcher';
import { debug } from 'console';

const MY_FORMATS = {
  parse: {
    dateInput: 'DD-MM-YYYY',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@Component({
  selector: 'itq-date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.scss'],
  providers: [
    DateRangeService,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class DateRangeComponent implements OnInit {
  @Input()
  set dates(value: IPresetQuery) {
    if (value) {
      const preset = DateRange.findPresetByLabel(value.label);
      let start: moment.Moment;
      let end: moment.Moment;
      if (value.label === DateQueryType.Custom) {
        start = moment(DateRange.convertFromEpochSeconds(value.start));
        end = moment(DateRange.convertFromEpochSeconds(value?.end));
      } else {
        start = preset.start;
        end = preset.end;
      }
      this._dates = {
        label: DateQueryType[value.label],
        start,
        end,
      };
      this.form.get('date.start')?.setValue(this._dates.start);
      this.form.get('date.end')?.setValue(this._dates.end || moment());
      this.dateRangeService.preset = this._dates;
    }
  }

  get dates(): IPreset {
    return this._dates;
  }

  @Input() form: FormGroup;
  @Input() disabled: boolean;
  @Input() class = 'w-64';
  @Input() required = true;

  public error: boolean;
  public customErrorStateMatcher = new CustomErrorStateMacther();
  public _dates: IPreset;

  @Output() search = new EventEmitter<IPresetQuery>();

  @ViewChild('picker') picker: MatDateRangePicker<Date>;

  private subscriptions = new Subscription();

  readonly DateRangeHeaderComponent = DateRangeHeaderComponent;
  readonly minDate = moment().subtract(3, 'year').toDate();
  readonly maxDate = new Date();
  readonly Sizes = Sizes;

  constructor(private dateRangeService: DateRangeService) {}

  ngOnInit(): void {
    this.bindChangeDatesSubscription();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes?.form?.currentValue?.get('date')) {
      this.addControls();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private addControls(): void {
    this.form.addControl(
      'date',
      new FormGroup(
        {
          start: new FormControl(
            this.dates?.start,
            this.required ? [Validators.required] : undefined,
          ),
          end: new FormControl(
            this.dates?.end,
            this.required ? [Validators.required] : undefined,
          ),
        },
        this.required ? { validators: this.dateRangeValidator } : undefined,
      ),
    );
  }

  private dateRangeValidator(
    group: FormGroup,
  ): { [key: string]: boolean } | null {
    const start = group.get('start').value;
    const end = group.get('end').value;
    return end && start <= end ? null : { invalidDateRange: true };
  }

  private bindChangeDatesSubscription(): void {
    this.subscriptions.add(
      this.dateRangeService.changeDates$.subscribe((response: IPreset) => {
        this.form.get('date.start').setValue(response.start);
        this.form.get('date.end').setValue(response.end);
      }),
    );
  }

  public onApply(picker: MatDateRangePicker<Date>): void {
    if (this.form?.get('date')?.valid) {
      this.onSearch();
    } else {
      if (!this.form.get('date.end')?.value) {
        this.form.get('date.end').setValue(this.form.get('date.start')?.value);
        this.onSearch();
      }
    }
  }

  public onClearDate(): void {
    this.dateRangeService.preset = undefined;
    this.form.get('date.start').setValue(undefined);
    this.form.get('date.end').setValue(undefined);
    this.onSearch();
  }

  private onSearch(): void {
    const preset = this.generatePreset();
    this.search.emit(preset);
  }

  private generatePreset(): IPresetQuery {
    const startDate: Moment = this.form.get('date.start').value;
    if (startDate) {
      const endDate: Moment = this.form.get('date.end').value;
      const label = DateRange.getPresetLabel(startDate, endDate);
      return {
        label: DateQueryType[label],
        start:
          DateQueryType[label] === DateQueryType.Custom
            ? DateRange.convertToEpochSec(startDate.toDate())
            : undefined,
        end:
          DateQueryType[label] === DateQueryType.Custom
            ? DateRange.convertToEpochSec(endDate.toDate())
            : undefined,
      };
    } else {
      return undefined;
    }
  }
}
