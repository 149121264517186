<textarea
  #textarea
  [name]="name"
  class="w-full"
  [rows]="rows"
  [(ngModel)]="query"
  placeholder="Please type to add board description"
  (blur)="onBlur()"
  (keyup)="onKeyUp()"
></textarea>
