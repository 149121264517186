import { AnalysisTypes, ChartType } from '../../shared/enums/widget.enum';
import { IWidget } from '../../shared/interfaces/widget.interface';

export abstract class Widget implements IWidget {
  constructor(
    public widgetId: string,
    public username: string,
    public type: AnalysisTypes,
    public name: string,
    public description: string,
    public chartType: ChartType,
    public ecosystemId: string,
    public updatedDate: number,
  ) {}

  public setSelectedSegment(options: any, segment: any): any {
    options?.series?.forEach((serie: any, index: number) => {
      serie.data = serie.data.map(
        (dataPoint: {
          name: string;
          value: number;
          tagId: string;
          unformattedName: string;
        }) => {
          if (
            dataPoint?.name === segment?.name &&
            index === segment?.seriesIndex
          ) {
            return {
              ...dataPoint,
              ...{ itemStyle: { color: '#1de62a' } },
            };
          } else {
            return {
              name: dataPoint.name,
              value: dataPoint.value,
              tagId: dataPoint.tagId,
              unformattedName: dataPoint?.unformattedName,
            };
          }
        },
      );
    });
    return options;
  }
}
