import { cloneDeep } from 'lodash';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FoamTree } from '@carrotsearch/foamtree';
import {
  CustomOverlayService,
  CustomOverlayType,
  DTOQueryConditionOperator,
  DTOQueryFieldType,
  IRiplResults,
  IconType,
  QueryFilters,
  TableColumn,
  TagCategory,
  VirtualScrollService,
} from '@intorqa-ui/core';
import { ShareEmailComponent } from '../share-email/share-email.component';
import { AddConnectionsComponent } from '../add-connections/add-connections.component';
import { PostNotesWizardComponent } from '../post-notes-wizard/post-notes-wizard.component';
import { ProfileDrildownScope } from '@portal/profiles/enums/profile.enum';
import { IAddConnection } from '@portal/profiles/interfaces/profile-connection.interface';
import { IAddNote } from '@portal/profiles/interfaces/profile-note.interface';
import { ProfileDrilldown } from '@portal/profiles/models/profile-drilldown';
import { LinkTag } from '@portal/profiles/models/profile-tags';
import { Query } from '@portal/shared/models/query-model';
import { Profile } from '@portal/profiles/models/profile';
import { TimelineStatus } from '@portal/shared/enums/timeline-feed.enum';
import {
  WidgetActions,
  ChartType,
  AnalysisTypes,
} from '@portal/shared/enums/widget.enum';
import {
  IData,
  DocumentItem,
} from '@portal/shared/interfaces/document.interface';
import {
  IWidgetData,
  IDataPoint,
} from '@portal/shared/interfaces/widget.interface';
import { Timeline } from '@portal/shared/models/timeline';
import { TagService } from '@portal/shared/pipes/tag.service';
import { SegmentScope } from '@portal/widget-settings/enums/widget-settings.enum';
import { ISegment } from '@portal/widget-settings/interfaces/widget-settings.interface';
import { NavigationHistoryItem } from '@portal/widget-settings/models/navigation-history-item.model';
import { ProfilesNavigationItem } from '@portal/widget-settings/models/profiles-navigation-item.model';
import { ViewMode } from '../toolbar/toolbar.enum';
import { TagAnalysis } from '@portal/boards/models/tag-analysis';
import { Widget } from '@portal/boards/models/widget';
import { WidgetService } from '@portal/boards/services/widget.service';
import { UserService } from '@portal/shared/services/user.service';

@Component({
  selector: 'itq-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.scss'],
})
export class SearchResultsComponent implements OnInit {
  @Input() viewMode: ViewMode;
  @Input() widgetData: IWidgetData;
  @Input() timelineData: IData;
  @Input() foamtreeData: IRiplResults;
  @Input() initialState: QueryFilters;
  @Input() query: Query;
  @Input() tagAnalysisWidget: TagAnalysis;
  @Input() widget: Timeline;
  @Input() segment: any;
  @Input() form: FormGroup;
  @Input() profile: Profile;
  @Input() navigationItem: NavigationHistoryItem;
  @Input() allowScrollingUp: boolean;

  @Output() drilldownFoamtree = new EventEmitter<any>();
  @Output() drilldown = new EventEmitter<{ segment: any; widget: Widget }>();
  @Output() updateFoamtreeRef = new EventEmitter<ElementRef>();
  @Output() dataBound = new EventEmitter<QueryFilters>();

  @ViewChild('template')
  template: TemplateRef<unknown>;

  @ViewChild('countTemplate') countTemplate: TemplateRef<unknown>;

  public foamtree: FoamTree;
  public widgetActions = [WidgetActions.EXPORT];
  public tableColumns: TableColumn[] = [];

  readonly WidgetActions = WidgetActions;
  readonly TimelineStatus = TimelineStatus;
  readonly ViewMode = ViewMode;
  readonly ChartType = ChartType;

  constructor(
    public widgetService: WidgetService,
    private customOverlayService: CustomOverlayService,
    readonly virtualScrollService: VirtualScrollService,
    readonly tagService: TagService,
    readonly userService: UserService,
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes?.widgetData?.previousValue !== changes?.widgetData?.currentValue
    ) {
      this.tableColumns = this.tagAnalysisWidget.getTableColumns(
        this.countTemplate,
      );
    }

    if (
      changes?.timelineData?.previousValue !==
      changes?.timelineData?.currentValue
    ) {
      this.tableColumns = this.widget.getTableColumns(this.template);
    }
  }

  onDrilldownFoamtree(cluster: any): void {
    this.drilldownFoamtree.emit(cluster);
  }

  onUpdateRef(foamtreeRef: ElementRef): void {
    this.updateFoamtreeRef.emit(foamtreeRef);
  }

  public onDrilldown(segment: ISegment): void {
    switch (segment.scope) {
      case SegmentScope.ACTOR:
        this.drilldownActor(segment);
        break;
      case SegmentScope.CHANNEL:
        this.drilldownChannel(segment);
        break;
      case SegmentScope.ARTICLE_DETAIL:
        this.openArticleDetail(segment);
        break;
      default:
        break;
    }
  }

  private drilldownChannel(segment: ISegment): void {
    let widget = new Timeline(
      this.widget.widgetId,
      undefined,
      AnalysisTypes.TIMELINE,
      segment.value.emitChannel,
      undefined,
      ChartType.TIMELINE,
      this.userService.userPreferences.defaultEcosystemId,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      false,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
    );
    const value = `Field Filter:${segment.value.emitChannel}:Channel`;
    const navigationItem = new NavigationHistoryItem(
      `${WidgetActions.DRILLDOWN}_${value}`,
      widget,
      WidgetActions.DRILLDOWN,
      [
        {
          field: DTOQueryFieldType.filter,
          operator: DTOQueryConditionOperator.in,
          value: [value],
        },
      ],
      new QueryFilters(30, 1, undefined, undefined, undefined),
      new FormGroup({}),
      'hashtag',
      IconType.FONT_AWESOME,
      this.navigationItem.selectedDisplayType,
      undefined,
      this.navigationItem.boardId,
    );
    this.widgetService.drilldownObservable.next(navigationItem);
  }

  private drilldownActor(segment: ISegment): void {
    let widget = new Timeline(
      undefined,
      undefined,
      AnalysisTypes.TIMELINE,
      segment.value.emitActor,
      undefined,
      ChartType.TIMELINE,
      this.userService.userPreferences.defaultEcosystemId,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      false,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
    );
    const value = `Field Filter:${segment.value.emitActor}:Actor`;
    const navigationItem = new NavigationHistoryItem(
      `${WidgetActions.DRILLDOWN}_${value}`,
      widget,
      WidgetActions.DRILLDOWN,
      [
        {
          field: DTOQueryFieldType.filter,
          operator: DTOQueryConditionOperator.in,
          value: [value],
        },
      ],
      new QueryFilters(30, 1, undefined, undefined, undefined),
      new FormGroup({}),
      'user',
      IconType.FONT_AWESOME,
      this.navigationItem.selectedDisplayType,
      undefined,
      this.navigationItem.boardId,
    );
    this.widgetService.drilldownObservable.next(navigationItem);
  }

  private openArticleDetail(segment: ISegment): void {
    this.widgetService.updateSegmentObservable.next(segment);
  }

  public onShare(document: DocumentItem): void {
    this.customOverlayService.open({
      data: {
        item: document,
      },
      type: CustomOverlayType['slide-right'],
      component: ShareEmailComponent,
      disposeOnNavigation: true,
    });
  }

  public onCreateProfile(profileDrilldown: ProfileDrilldown): void {
    const profile = new Profile(
      undefined,
      undefined,
      AnalysisTypes.PROFILE,
      profileDrilldown.scope === ProfileDrildownScope.TAG
        ? this.widget.name
        : profileDrilldown.value,
      undefined,
      ChartType.PROFILE,
      this.userService.userPreferences.defaultEcosystemId,
      profileDrilldown.profileType.id,
      profileDrilldown.profileType.name,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
    );
    const navigationItem = new ProfilesNavigationItem(
      `${WidgetActions.CREATE}_profiles`,
      profile,
      WidgetActions.CREATE,
      undefined,
      new QueryFilters(30, 1, undefined, undefined, undefined),
      new FormGroup({}),
      'plus',
      IconType.FONT_AWESOME,
      undefined,
      undefined,
      undefined,
      new LinkTag(
        undefined,
        undefined,
        profileDrilldown.scope === ProfileDrildownScope.TAG
          ? TagCategory['My Tags']
          : profileDrilldown.scope === ProfileDrildownScope.ACTOR
            ? TagCategory.Actor
            : TagCategory.Channel,
        true,
        profileDrilldown.scope === ProfileDrildownScope.TAG
          ? this.widget.name
          : profileDrilldown.value,
        undefined,
        profileDrilldown.scope === ProfileDrildownScope.TAG
          ? profileDrilldown.value
          : `Field field:${profileDrilldown.value}:${
              profileDrilldown.scope === ProfileDrildownScope.ACTOR
                ? TagCategory.Actor
                : TagCategory.Channel
            }`,
      ),
      undefined,
      undefined,
      0,
    );
    this.widgetService.drilldownObservable.next(navigationItem);
  }

  public onLoadCount(row: IDataPoint): void {
    const timeline = new Timeline(
      this.widget.widgetId,
      this.widget.username,
      AnalysisTypes.TIMELINE,
      `${this.widget.name}: ${row.category}`,
      undefined,
      ChartType.TIMELINE,
      this.userService.userPreferences.defaultEcosystemId,
      undefined,
      undefined,
      undefined,
      undefined,
      this.tagAnalysisWidget.dataSource,
      undefined,
      undefined,
      undefined,
      this.widget.categoryId,
      this.widget.lastTaggingTime,
      this.widget.updatedDate,
      this.widget.alertTypeId,
      undefined,
      undefined,
    );
    const navigationItem = new NavigationHistoryItem(
      `${timeline.widgetId}_${WidgetActions.DRILLDOWN}_${row.tagId}`,
      cloneDeep(timeline),
      WidgetActions.DRILLDOWN,
      [
        {
          field:
            row.tagId.search('Field Filter') > -1
              ? DTOQueryFieldType.filter
              : DTOQueryFieldType.tag,
          operator: DTOQueryConditionOperator.in,
          value: [row.tagId],
        },
        {
          field: DTOQueryFieldType.tag,
          operator: DTOQueryConditionOperator.in,
          value: timeline.dataSource,
        },
      ],
      this.initialState,
      new FormGroup({}),
      'search',
      IconType.FONT_AWESOME,
      {
        id: 'Timeline',
        type: ChartType.TIMELINE,
        svgIcon: 'board',
        tooltip: 'Timeline',
      },
      undefined,
      this.navigationItem.boardId,
    );

    this.widgetService.drilldownObservable.next(navigationItem);
  }

  public onAddConnection(params: IAddConnection): void {
    this.customOverlayService.open({
      data: params,
      type: CustomOverlayType['slide-right'],
      component: AddConnectionsComponent,
      disposeOnNavigation: true,
    });
  }

  public onAddNote(params: IAddNote): void {
    this.customOverlayService.open({
      data: params,
      size: 'lg',
      type: CustomOverlayType['slide-right'],
      component: PostNotesWizardComponent,
      disposeOnNavigation: true,
    });
  }
}
