import { IButtonThumbnail } from '@intorqa-ui/core';
import { QueryType } from '../enums/timeline-query.enum';

export const SEARCH_TYPES: Array<IButtonThumbnail> = [
  {
    id: QueryType.QUICK_SEARCH,
    icon: ['far', 'tags'],
    tooltip: 'Search everything',
  },
  {
    id: QueryType.ACTOR,
    icon: ['far', 'user'],
    tooltip: 'Find an actor',
  },
  {
    id: QueryType.CHANNEL,
    icon: ['far', 'hashtag'],
    tooltip: 'Find a channel',
  },
  {
    id: QueryType.QUERY_BUILDER,
    icon: ['fab', 'searchengin'],
    tooltip: 'Query builder',
  },
];
