import { Injectable } from '@angular/core';
import { FoamTree } from '@carrotsearch/foamtree';
import { ApiRequestService, DTOTypeConverter } from '@intorqa-ui/api';
import { IRiplResults } from './foamtree.interface';
import {
  IData,
  ISearchResults,
} from '@portal/shared/interfaces/document.interface';
import { DateQueryType } from '../../enums/date-range.enum';
import { DateRange, DTOQuery, QueryFilters } from '@intorqa-ui/core';

@Injectable({
  providedIn: 'root',
})
export class FoamtreeService {
  public foamtreeData;

  constructor(private apiRequestService: ApiRequestService) {}

  getFoamtree(
    state: QueryFilters,
    query: DTOQuery,
    ecosystemId: string,
  ): Promise<IRiplResults> {
    let pageQuery = '';
    if (state?.where) {
      if (state.where.label === DateQueryType.Custom) {
        pageQuery += `&dateFrom=${state.where?.start}`;
        pageQuery += `&dateTo=${state.where?.end}`;
      } else {
        let preset = DateRange.findPresetByLabel(state.where.label);
        pageQuery += `&dateFrom=${DateRange.convertToEpochSec(preset?.start.toDate())}`;
        pageQuery += `&dateTo=${DateRange.convertToEpochSec(preset?.end.toDate())}`;
      }
    }
    if (ecosystemId) {
      pageQuery += `&ecosystemId=${ecosystemId}`;
    }
    return this.apiRequestService
      .post(
        `/document/cluster/search?size=5000${pageQuery}`,
        new DTOTypeConverter<IRiplResults>(),
        JSON.stringify(query),
      )
      .then((response: IRiplResults) => {
        this.foamtreeData = response;
        return response;
      });
  }

  getClusterDocumentIndex(cluster: Array<any>): Array<number> {
    if (cluster) {
      let documentIndex = [];
      cluster.map((item: any) => {
        if (item) {
          documentIndex = [...documentIndex, ...item.documents];
          item.groups.map((elem: any) => {
            documentIndex = [...documentIndex, ...elem.documents];
            if (elem.groups.length > 0) {
              const docIds = this.getClusterDocumentIndex(elem.groups);
              documentIndex = [...documentIndex, ...docIds];
            }
          });
        }
      });

      return documentIndex;
    }
  }

  removeDocuments(documents: Array<number>): Array<number> {
    documents = documents.slice().sort();
    let copyDocuments = [...documents];
    for (let i = 0; i < documents.length - 1; i++) {
      if (documents[i + 1] === documents[i]) {
        copyDocuments = copyDocuments.filter((d: number) => {
          return d !== documents[i];
        });
      }
    }
    return copyDocuments;
  }

  getClusterDocumentIds(cluster: Array<any>): Array<number> {
    const result = [];
    const documentIndex = this.getClusterDocumentIndex(cluster);
    const nonDuplicateDocs = this.removeDocuments(documentIndex);

    nonDuplicateDocs.map((item: number) => {
      if (
        !result.includes(this.foamtreeData.source_documents[item]) &&
        this.foamtreeData.source_documents[item]
      ) {
        result.push(this.foamtreeData.source_documents[item]);
      }
    });

    return result;
  }

  convert(clusters): any {
    const result = [];
    clusters?.map((cluster) => {
      result.push({
        id: cluster.id,
        label: cluster.labels.join(', '),
        weight: cluster.score,
        groups: cluster.clusters ? this.convert(cluster.clusters) : [],
        documents: cluster.documents,
      });
    });
    return result;
  }

  exportImage(name: string, foamtreeRef: FoamTree): void {
    const imageBase64 = foamtreeRef.get('imageData', 'image/png');
    const element = document.createElement('a');
    element.setAttribute('href', imageBase64);
    element.setAttribute('download', name + '.png');

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  filterCluster(query: { documentIds: Array<number> }): Promise<IData> {
    return new Promise((resolve, reject) => {
      this.apiRequestService
        .post(
          '/document/ids',
          new DTOTypeConverter<ISearchResults>(),
          JSON.stringify(query),
        )
        .then((response: ISearchResults) => {
          const data = {
            result: response.items,
            count: response.totalHits,
          } as IData;
          resolve(data);
        });
    });
  }
}
