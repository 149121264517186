import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  Category,
  DTOQueryFieldType,
  QueryFilters,
  SectionIcons,
  Sections,
  Sizes,
  TagCategory,
  VirtualScrollService,
} from '@intorqa-ui/core';
import { ITagMetadata } from '@portal/shared/interfaces/tag.interface';
import { IDisplayType } from '@portal/shared/interfaces/widget.interface';
import { Query } from '@portal/shared/models/query-model';
import { Timeline } from '@portal/shared/models/timeline';
import { TagService } from '@portal/shared/pipes/tag.service';
import { NavigationHistoryItem } from '@portal/widget-settings/models/navigation-history-item.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'itq-timeline-type-all',
  templateUrl: './timeline-type-all.component.html',
  styleUrls: ['./timeline-type-all.component.scss'],
})
export class TimelineTypeAllComponent implements OnInit {
  @Input() widget: Timeline;
  @Input() query: Query;
  @Input() initialState: QueryFilters;
  @Input() timeline: Timeline;
  @Input() navigationItem: NavigationHistoryItem;
  @Input() required = true;
  @Input() dataSource: Array<Category>;

  @Output() dataBound = new EventEmitter<Query>();
  @Output() clearFilters = new EventEmitter<Query>();

  public displayTypes: Array<IDisplayType> = [];
  public selectedSearchType: IDisplayType;
  public sections: Array<String> = [];
  public form: FormGroup;
  public searchTerm: string;
  private resetQuerySubscription: Subscription;

  readonly Sizes = Sizes;
  readonly Sections = Sections;
  readonly SectionIcons = SectionIcons;
  readonly TagCategory = TagCategory;

  constructor(
    private virtualScrollingService: VirtualScrollService,
    readonly tagService: TagService,
  ) {}

  ngOnInit(): void {
    this.resetQuerySubscription = this.tagService.resetQuery$.subscribe(() => {
      this.searchTerm = undefined;
    });
    const queryRule = this.query.getRuleValueByField(DTOQueryFieldType.content);
    this.searchTerm = queryRule?.value?.toString();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes?.navigationItem?.previousValue !==
      changes?.navigationItem?.currentValue
    ) {
      const queryRule = this.query.getRuleValueByField(
        DTOQueryFieldType.content,
      );
      this.searchTerm = queryRule?.value.toString();
    }
  }

  ngOnDestroy(): void {
    this.resetQuerySubscription.unsubscribe();
  }

  public onTabChange(): void {
    this.tagService.reloadFilters$.next({
      queryFilters: new QueryFilters(
        100,
        1,
        this.initialState.where,
        undefined,
        undefined,
      ),
      query: this.query,
    });
  }

  public onChangeSearch(searchType: IDisplayType): void {
    this.selectedSearchType = searchType;
  }

  public onSearch(): void {
    if (!this.searchTerm) {
      this.initialState.query = undefined;
      this.onRemoveTag({
        tagName: undefined,
        tagId: this.searchTerm,
        included: true,
        excluded: false,
        categoryName: TagCategory.content,
        section: undefined,
      });
    } else {
      this.initialState.query = this.searchTerm;
      this.onAddTag({
        tagName: undefined,
        tagId: this.searchTerm,
        included: true,
        excluded: false,
        categoryName: TagCategory.content,
        section: undefined,
      });
    }
  }

  public onAddTag(item: ITagMetadata): void {
    this.initialState.resetPagination().then(() => {
      this.virtualScrollingService.dataBoundObservable.next();
    });
    const query = this.query.cloneDeep();
    query.addSelection(item);
    this.dataBound.emit(query);
  }

  public onRemoveTag(item: ITagMetadata): void {
    this.initialState.resetPagination().then(() => {
      this.virtualScrollingService.dataBoundObservable.next();
    });
    if (item.categoryName === TagCategory.content) {
      this.searchTerm = undefined;
    }
    this.query.removeSelection(item);
    this.dataBound.emit(this.query);
  }

  public onDataBound(queryModel: Query): void {
    this.dataBound.emit(queryModel);
  }

  public onClearFilters(): void {
    this.searchTerm = undefined;
    this.clearFilters.emit(new Query([], this.query.type));
  }
}
