import { Injectable, Inject } from '@angular/core';
import { ApiConfigService } from '@intorqa-ui/api';
import eventbus from '@vertx/eventbus-bridge-client.js';

@Injectable({
  providedIn: 'root',
})
export class EventBusService {
  public eventBus: Promise<eventbus.EventBus>;
  public registeredEvents: Map<string, any> = new Map();
  public onOpen: Promise<void>;

  constructor(@Inject(ApiConfigService) private config: any) {
    const connection = this.connect();
    this.eventBus = connection.then((conn) => conn.eventBus);
    this.onOpen = connection.then((conn) => conn.onOpen);
  }

  async connect(): Promise<{
    eventBus: eventbus.EventBus;
    onOpen: Promise<void>;
  }> {
    console.log('Connecting to eventbus');

    const options = {
      vertxbus_reconnect_attempts_max: Infinity,
      vertxbus_reconnect_delay_min: 1000,
      vertxbus_reconnect_delay_max: 5000,
      vertxbus_reconnect_exponent: 2,
      vertxbus_randomization_factor: 0.5,
    };
    const eb = new eventbus(`${this.config.evBusUrl}`, options);
    eb.enableReconnect(true);
    const onOpen = new Promise<void>((resolve) => {
      eb.onopen = () => {
        console.log('Event bus connected...');
        this.reRegisterEvents(eb); // Re-register events upon reconnection
        resolve();
      };
    });
    eb.onerror = () => console.log('Event bus error');
    eb.onclose = () => {
      console.log('Event bus disconnected, attempting to reconnect...');
    };

    return { eventBus: eb, onOpen };
  }

  async send(address: string, message: any): Promise<void> {
    this.eventBus.then((eb: eventbus.EventBus) => {
      eb.send(address, message);
    });
  }

  async registerEvent(address: string, handler: any): Promise<void> {
    if (this.registeredEvents.has(address)) {
      console.log(`Event ${address} is already registered`);
      return;
    }
    await this.onOpen;
    this.eventBus.then((eb: any) => {
      console.log(address + ' registered');
      if (eb.state === 1) {
        eb.registerHandler(address, handler);
        this.registeredEvents.set(address, handler);
      }
    });
  }

  unRegisterEvent(address: string, handler: any): void {
    if (this.registeredEvents.has(address)) {
      this.eventBus.then((eb: eventbus.EventBus) => {
        eb?.unregisterHandler(address, handler);
        this.registeredEvents.delete(address);
      });
    }
  }

  private reRegisterEvents(eb: eventbus.EventBus): void {
    this.registeredEvents.forEach((handler, address) => {
      eb.registerHandler(address, handler);
      console.log(`Re-registered event ${address}`);
    });
  }

  public async disconnect(): Promise<void> {
    this.eventBus.then((eb: eventbus.EventBus) => {
      this.registeredEvents.forEach((handler, address) => {
        eb.unregisterHandler(address, handler);
      });
      this.registeredEvents.clear();
      eb.close();
    });
  }
}
