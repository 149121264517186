import { IProfileNote } from '../interfaces/profile-note.interface';

export class ProfileNote implements IProfileNote {
  constructor(
    public id: string,
    public profileId: string,
    public typeId: string,
    public documentId: string,
    public updatedDate: number,
    public updatedBy: string,
    public ecosystemId: string,
  ) {}
}
