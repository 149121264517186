<div
  class="multiple_dropdown__header"
  (mousedown)="$event.stopImmediatePropagation()"
>
  <itq-input
    class="w-full"
    #inputControl
    [(ngModel)]="query"
    [placeholder]="'Search...'"
    (search)="onFilter(true)"
    (clear)="onFilter(true)"
  ></itq-input>
</div>
<div class="dropdown-container">
  <ng-container *ngIf="results?.length > 0; else noResultsTemplate">
    <mat-list role="list" #listSearch (scroll)="onScroll()">
      <mat-list-item role="listitem" *ngFor="let item of results">
        <div [ngClass]="{ noresults: item?.count === 0 }">
          <mat-checkbox
            class="flex overflow-hidden"
            (click)="$event.stopImmediatePropagation()"
            (change)="onChangeValue(item)"
            [checked]="item | isSelectionChecked: selections : dataFields"
            [color]="'primary'"
          >
            <span
              [ngClass]="{
                noresults: item?.count === 0,
                'overflow-hidden flex': true
              }"
            >
              <span class="flex-1 text-ellipsis overflow-hidden">
                {{ item[dataFields?.name] }} </span
              ><strong *ngIf="item?.count">({{ item.count }})</strong>
            </span>
          </mat-checkbox>
        </div>
      </mat-list-item>
    </mat-list>
  </ng-container>

  <ng-template #noResultsTemplate>
    @if (dataSource?.length === 0) {
      <span
        class="font-semibold italic text-sm text-gray-700 m-2 flex items-center"
      >
        <fa-icon [icon]="['far', 'info-circle']"></fa-icon>
        {{ emptyDataSourceMessage }}
      </span>
    } @else {
      <itq-loader class="p-4"></itq-loader>
    }
  </ng-template>
</div>
<itq-loader *ngIf="showLoader"></itq-loader>
