<div
  [ngClass]="{
    input__wrapper: true,
    icon: icon ? true : false,
    hasValue: searchTerm && searchTerm !== '' ? true : false
  }"
  (click)="$event.stopImmediatePropagation()"
>
  @if (icon) {
    <fa-icon class="input__icon" [icon]="['far', icon]" [size]="'lg'"></fa-icon>
  }

  <input
    #input
    class="form-control"
    [type]="type"
    [required]="required"
    [placeholder]="placeholder"
    [(ngModel)]="searchTerm"
    [disabled]="disabled"
    [autofocus]="autoFocus"
    (blur)="onBlur()"
    (keyup.enter)="onSubmit($event)"
    (focus)="onFocus()"
    (click)="$event.stopPropagation()"
    (keydown)="dirty = true; $event.stopPropagation()"
  />
  @if (searchTerm && searchTerm !== '') {
    <itq-mat-button
      [class]="'btn--delete'"
      [materialStyle]="'basic'"
      [type]="'button'"
      [disabled]="disabled"
      (mouseDownEvent)="onClear($event)"
    >
      <fa-icon [icon]="['far', 'times']" [size]="'lg'"></fa-icon>
    </itq-mat-button>
  }
</div>
