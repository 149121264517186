<span [ngClass]="{ label: true, 'font-semibold': true, 'mb-1': true, required }"
  >Build your query:</span
>
<query-builder
  *ngIf="queryModel?.query"
  [(ngModel)]="queryModel.query"
  [config]="builderConfig"
  [allowCollapse]="true"
  emptyMessage="A group cannot be empty. Please add a rule or remove it all together."
>
  <ng-container
    *queryButtonGroup="
      let ruleset;
      let addRule = addRule;
      let addRuleSet = addRuleSet;
      let removeRuleSet = removeRuleSet
    "
  >
    <div
      class="query-builder_actions"
      role="group"
      aria-label="Query builder group"
    >
      <itq-mat-button
        [padding]="Sizes.SM"
        [materialStyle]="'basic'"
        (clickEvent)="addRule()"
      >
        <fa-icon [icon]="['far', 'plus']"></fa-icon>
        Rule
      </itq-mat-button>
      <itq-mat-button
        *ngIf="addRuleSet"
        [padding]="Sizes.SM"
        [materialStyle]="'basic'"
        (clickEvent)="addRuleSet()"
      >
        <fa-icon [icon]="['far', 'plus']"></fa-icon>
        Group
      </itq-mat-button>

      <itq-mat-button
        *ngIf="removeRuleSet"
        [padding]="Sizes.SM"
        [materialStyle]="'basic'"
        (clickEvent)="removeRuleSet(); onChange()"
      >
        <fa-icon [icon]="['far', 'trash-alt']"></fa-icon>
        Group
      </itq-mat-button>
    </div>
  </ng-container>
  <ng-container *queryRemoveButton="let rule; let removeRule = removeRule">
    <div
      class="q-element remove-button__container"
      *ngIf="queryModel.getRules().length > 1"
    >
      <itq-mat-button
        *ngIf="removeRule"
        class="btn-removeRule"
        [materialStyle]="'basic'"
        [padding]="Sizes.NONE"
        [type]="'button'"
        [matTooltip]="'Delete rule'"
        (clickEvent)="removeRule(rule); onChange()"
      >
        <fa-icon [icon]="['far', 'trash-alt']"></fa-icon>
      </itq-mat-button>
    </div>
  </ng-container>
  <ng-container *querySwitchGroup="let ruleset">
    <div class="operators__container">
      <div
        class="btn-group"
        role="group"
        aria-label="queryButtonGroup"
        *ngIf="ruleset"
      >
        <itq-mat-button
          [type]="'button'"
          [materialStyle]="'flat'"
          [style]="ruleset.condition === 'and' ? 'accent' : undefined"
          (mouseDownEvent)="andClick(ruleset)"
        >
          And
        </itq-mat-button>
        <itq-mat-button
          [type]="'button'"
          [materialStyle]="'flat'"
          [style]="ruleset.condition === 'or' ? 'accent' : undefined"
          (mouseDownEvent)="orClick(ruleset)"
        >
          Or
        </itq-mat-button>
      </div>
    </div>
  </ng-container>
  <ng-container *queryField="let rule; let fields = fields">
    <div class="q-element fields_container">
      <itq-dropdown
        [(ngModel)]="rule.field"
        [clearSelection]="false"
        [dataSource]="fields"
        (changeValue)="onChangeDataField(rule, fields)"
        [dataFields]="{ name: 'name', value: 'value' }"
      ></itq-dropdown>
    </div>
  </ng-container>
  <ng-container *queryOperator="let rule; let fields = fields">
    <div class="q-element operator__container">
      <itq-dropdown
        [(ngModel)]="rule.operator"
        [showHeader]="false"
        [clearSelection]="false"
        [dataSource]="getFieldOperators(rule.field)"
        [dataFields]="{ name: 'name', value: 'value' }"
        (changeValue)="onChange()"
      ></itq-dropdown>
    </div>
  </ng-container>

  <ng-container *queryInput="let rule; type: 'textarea'">
    <div class="q-element value">
      <itq-input
        [(ngModel)]="rule.value"
        [placeholder]="'Add search term...'"
        [submitOnEnter]="true"
        (blurEvent)="onChange()"
        (search)="onChange()"
        (clear)="onChange()"
      ></itq-input>
    </div>
  </ng-container>
  <ng-container *queryInput="let rule; type: 'tag'">
    <div class="q-element value">
      <itq-multiple-dropdown
        [(ngModel)]="rule.value"
        [dataFields]="{ name: 'name', value: 'id' }"
        [dataSource]="
          tagsDataSource
            | filterSelections
              : query
              : (widget.dataSource && widget.dataSource?.length > 0
                  ? widget?.dataSource[0]
                  : '')
        "
        [query]="searchTerm"
        [httpBinding]="true"
        [emptyDataSourceMessage]="'No tags found!'"
        (changeValue)="onChangeValue($event, rule)"
        (dataBound)="onGetTags(rule, $event)"
      >
      </itq-multiple-dropdown>
    </div>
  </ng-container>
</query-builder>
<div class="selections__container" *ngIf="queryModel?.selections?.length > 0">
  <itq-mat-button
    [materialStyle]="'basic'"
    [type]="'button'"
    (clickEvent)="onClearFilters()"
  >
    <fa-icon [icon]="['far', 'trash-alt']"></fa-icon>
    Clear query
  </itq-mat-button>
</div>
