import { IPresetQuery } from '@intorqa-ui/core';
import { IBoard } from '../interfaces/board.interface';

export class Board implements IBoard {
  constructor(
    public id: string,
    public name: string,
    public description: string,
    public defaultBoard: boolean,
    public filter: {
      date: IPresetQuery;
    },
    public widgetIds: Array<string>,
    public updatedDate: number,
  ) {}
}
