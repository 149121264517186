import { Component, Inject, OnInit } from '@angular/core';

import { MatSnackBar } from '@angular/material/snack-bar';
import {
  CustomOverlayConfig,
  CUSTOM_OVERLAY_DATA,
  Sizes,
} from '@intorqa-ui/core';
import { ApiSettingsService } from './api-settings.service';
import { Timeline } from '@portal/shared/models/timeline';
import { TagService } from '@portal/shared/pipes/tag.service';

@Component({
  selector: 'itq-api-settings',
  templateUrl: './api-settings.component.html',
  styleUrls: ['./api-settings.component.scss'],
})
export class ApiSettingsComponent implements OnInit {
  public showLoader = true;
  public apiKey: string;
  public externalApiBase: string;
  public showConfirmationPanel = false;
  public curlCommand: string;
  public baseUrl: string;
  public widget: Timeline;
  public apiKeyPrefix: string;

  readonly Sizes = Sizes;

  constructor(
    @Inject(CUSTOM_OVERLAY_DATA) public config: CustomOverlayConfig,
    public apiSettingsService: ApiSettingsService,
    private snackBar: MatSnackBar,
    private tagService: TagService,
  ) {
    this.externalApiBase = window.location.origin.replace('saas', 'api');
  }

  ngOnInit(): void {
    this.widget = this.config.data.widget;
    this.tagService
      .getApiKeyPrefix(this.widget.dataSource[0])
      .then((response: string) => {
        this.apiKeyPrefix = response;
        this.showLoader = false;
      })
      .catch(() => {
        this.showLoader = false;
      });
    this.curlCommand = this.getCurrentDateEpochSec();
    this.baseUrl =
      '<strong>Base Url: </strong>' +
      this.externalApiBase +
      '/key/' +
      (this.apiKey ? this.apiKey : '<strong>%yourKey%<strong>');
  }

  getCurrentDateEpochSec(): string {
    const currentEpochSeconds = Math.trunc(new Date().getTime() / 1000);
    return (
      "curl -i -H 'Accept: application/json' -H 'Content-Type: application/json' -X GET '" +
      this.externalApiBase +
      '/key/' +
      (this.apiKey ? this.apiKey : '<strong>%yourKey%</strong>') +
      '?page=1&pageSize=100&fromEpochSeconds=0&toEpochSeconds=' +
      currentEpochSeconds +
      "'"
    );
  }

  onGenerateKey(): void {
    if (!this.apiKeyPrefix) {
      this.showLoader = true;
      this.apiSettingsService
        .generateKey(this.widget.dataSource[0])
        .then((response: { apiKey: string; prefix: string }) => {
          this.apiKey = response.apiKey;
          this.curlCommand = this.getCurrentDateEpochSec();
          this.baseUrl =
            '<strong>Base Url: </strong>' +
            this.externalApiBase +
            '/key/' +
            (this.apiKey ? this.apiKey : '<strong>%yourKey%<strong>');
          this.apiKeyPrefix = response.prefix;
          this.showConfirmationPanel = false;
          this.showLoader = false;
        })
        .catch(() => {
          this.showLoader = false;
        });
    } else {
      this.showConfirmationPanel = true;
    }
  }

  copy(scope: string): void {
    let title: string;
    let message: string;
    switch (scope) {
      case 'key':
        title = 'Api key copied!';
        message = 'Your Intorqa api key has been copied successfully!';
        break;
      case 'url':
        title = 'Url copied!';
        message = 'Url has been copied successfully!';
        break;
      default:
        title = 'Curl command copied!';
        message = 'The Curl command has been copied successfully!';
        break;
    }
    this.snackBar.open(message, 'Close', {
      horizontalPosition: 'right',
      duration: 5000,
      verticalPosition: 'top',
    });
  }
}
