import { Pipe, PipeTransform } from '@angular/core';
import { DiscordNavigationItem } from '@portal/widget-settings/models/discord-navigation-item.model';
import { NavigationHistoryItem } from '@portal/widget-settings/models/navigation-history-item.model';

@Pipe({
  name: 'isDiscordNavigation',
})
export class IsDiscordNavigationPipe implements PipeTransform {
  constructor() {}

  transform(navigationItem: NavigationHistoryItem): boolean {
    return navigationItem instanceof DiscordNavigationItem;
  }
}
