import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepper } from '@angular/material/stepper';
import {
  Align,
  Category,
  CustomOverlayRef,
  CustomOverlayService,
  DTOQueryConditionOperator,
  DTOQueryFieldType,
  DialogComponent,
  DialogTypes,
  IError,
  IPresetQuery,
  IconType,
  PillType,
  QueryFilters,
  Sizes,
  TableColumn,
} from '@intorqa-ui/core';
import { IBoard } from '@portal/boards/interfaces/board.interface';
import { Board } from '@portal/boards/models/board';
import { BoardService } from '@portal/boards/services/board.service';
import { WidgetService } from '@portal/boards/services/widget.service';
import { AlertTypes } from '@portal/notifications/enums/alerts.enum';
import {
  ICreateTagMatchAlertDTO,
  ICreateTagThresholdAlertDTO,
} from '@portal/notifications/interfaces/alerts.interface';
import {
  Alert,
  TagMatchAlert,
  TagThresholdAlert,
} from '@portal/notifications/models/alert';
import { AlertsService } from '@portal/notifications/services/alerts.service';
import { TimelineStatus } from '@portal/shared/enums/timeline-feed.enum';
import { QueryType } from '@portal/shared/enums/timeline-query.enum';
import {
  AnalysisTypes,
  ChartType,
  WidgetActions,
} from '@portal/shared/enums/widget.enum';
import { IData } from '@portal/shared/interfaces/document.interface';
import { ITagMetadata } from '@portal/shared/interfaces/tag.interface';
import { Query } from '@portal/shared/models/query-model';
import { QueryRule } from '@portal/shared/models/query-rule';
import { Tag } from '@portal/shared/models/tag';
import { Timeline } from '@portal/shared/models/timeline';
import { TagService } from '@portal/shared/pipes/tag.service';
import { CategoryService } from '@portal/shared/services/category.service';
import { EcosystemsService } from '@portal/shared/services/ecosystems.service';
import { UserService } from '@portal/shared/services/user.service';
import { ISegment } from '@portal/widget-settings/interfaces/widget-settings.interface';
import { NavigationHistoryItem } from '@portal/widget-settings/models/navigation-history-item.model';
import { WidgetSettingsService } from '@portal/widget-settings/services/widget-settings.service';
import { KeycloakService } from 'keycloak-angular';
import { cloneDeep } from 'lodash';
import {
  Observable,
  Subscription,
  concat,
  forkJoin,
  fromEvent,
  of,
} from 'rxjs';

@Component({
  selector: 'itq-timeline-wizard',
  templateUrl: './timeline-wizard.component.html',
  styleUrls: ['./timeline-wizard.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
  ],
})
export class TimelineWizardComponent implements OnInit, AfterViewInit {
  @Input() navigationItem: NavigationHistoryItem;
  @Input() articleDetail: ISegment;
  @Input() form: FormGroup;

  public infoForm: FormGroup;
  public metadataForm: FormGroup;
  public contentForm: FormGroup;
  public expandedFilters = true;
  public widget: Timeline;
  public timelineDataSource: IData;
  public query = new Query();
  public initialState: QueryFilters;
  public tableColumns: TableColumn[] = [];
  public timelines: Array<Timeline> = [];
  public categoriesDataSource: Array<Category>;
  private stepperClickSubscription: Subscription;
  public boardIds: Array<string>;
  public alertType: string;
  private loadTypesSubscription: Subscription;
  private loadAlertSubscription: Subscription;
  private alert: Alert;
  private changeAlertTypeSubscription: Subscription;
  private getFiltersSubscription: Subscription;

  readonly Sizes = Sizes;
  readonly PillType = PillType;
  readonly IconType = IconType;
  readonly TimelineStatus = TimelineStatus;
  readonly Align = Align;
  readonly WidgetActions = WidgetActions;

  @ViewChild('deleteButtonTemplate') deleteButtonTemplate: TemplateRef<unknown>;
  @ViewChild('ownerTemplate') ownerTemplate: TemplateRef<unknown>;
  @ViewChild('relationshipDropdownTemplate')
  relationshipDropdownTemplate: TemplateRef<unknown>;
  @ViewChild('typeTemplate')
  typeTemplate: TemplateRef<unknown>;
  @ViewChild('template')
  template: TemplateRef<unknown>;
  @ViewChild('stepper')
  stepper: MatStepper;

  constructor(
    private tagService: TagService,
    private snackBar: MatSnackBar,
    private boardService: BoardService,
    private customOverlayService: CustomOverlayService,
    private customOverlayRef: CustomOverlayRef,
    private categoryService: CategoryService,
    readonly alertService: AlertsService,
    readonly ecosystemService: EcosystemsService,
    readonly cdr: ChangeDetectorRef,
    readonly keycloakService: KeycloakService,
    readonly widgetService: WidgetService,
    readonly userService: UserService,
    readonly widgetSettingsService: WidgetSettingsService,
  ) {}

  ngOnInit(): void {
    this.changeAlertTypeSubscription =
      this.tagService.changeAlertType$.subscribe((response: string) => {
        this.alertType = this.alertService.getTypeById(response)?.label;
        this.form.get('alerts').reset();
      });
    this.loadAlertSubscription = this.alertService.loadAlert$.subscribe(
      (response: Alert) => {
        this.alert = response;
      },
    );
    this.loadTypesSubscription = this.alertService.loadTypes$.subscribe(() => {
      this.alertType = this.alertService.getTypeById(
        this.metadataForm?.get('alertTypeId')?.value,
      )?.label;
    });
    this.getFiltersSubscription = this.tagService.getFilters$.subscribe(() => {
      this.getFilters();
    });
    this.widget = cloneDeep(this.navigationItem.item);
    this.initialState = this.navigationItem.initialState;
    if (this.navigationItem.boardId) {
      this.boardIds = [this.navigationItem.boardId];
    }
    this.createForm();
    this.loadQuery();
    this.getFilters();
    if (
      this.navigationItem.action === WidgetActions.SETTINGS ||
      this.navigationItem.action === WidgetActions.CLONE ||
      this.navigationItem.rules?.length > 0 ||
      this.query.hasRules()
    ) {
      this.getTimelineData();
    } else {
      this.widgetSettingsService.loader$.next(false);
    }
    if (
      this.navigationItem.action === WidgetActions.SETTINGS ||
      this.navigationItem.action === WidgetActions.CLONE
    ) {
      this.getBoardsForTag();
    }
  }

  private getBoardsForTag(): void {
    if (
      this.navigationItem?.id !== `tag_manager_${WidgetActions.CLONE}` &&
      this.navigationItem?.id !== `board_${WidgetActions.CLONE}`
    ) {
      this.boardService
        .getTags(this.widget.dataSource[0])
        .then((response: Array<string>) => {
          this.boardIds = response;
        });
    }
  }

  ngAfterViewInit(): void {
    this.initColumns();
    const stepperClick = fromEvent(
      document.getElementsByTagName('mat-step-header'),
      'click',
    );
    this.stepperClickSubscription = stepperClick.subscribe(() => {
      this.infoForm.markAllAsTouched();
    });
  }

  ngOnDestroy(): void {
    this.stepperClickSubscription.unsubscribe();
    this.loadTypesSubscription.unsubscribe();
    this.loadAlertSubscription.unsubscribe();
    this.changeAlertTypeSubscription.unsubscribe();
    this.getFiltersSubscription.unsubscribe();
  }

  public onChangeDates(params: IPresetQuery): void {
    this.initialState.where = params;
    this.getTimelineData();
  }

  initColumns(): void {
    this.tableColumns = [
      {
        name: undefined,
        dataKey: 'result',
        isSortable: true,
        customRender: true,
        template: this.template,
      },
    ];
  }

  private loadSelections(): void {
    let tagIds = this.query.getTagIds();
    let fieldFilterIds = this.query.getFieldFilterTagIds();
    let contentTag = this.query.getContentTag();
    this.tagService
      .getSelections(
        tagIds,
        fieldFilterIds,
        contentTag,
        this.categoryService.categories,
      )
      .then((response: Array<ITagMetadata>) => {
        this.query.selections = response;
      });
  }

  private loadQuery(): void {
    this.contentForm.addControl(
      'query',
      new FormControl(undefined, [Validators.required]),
    );
    if (this.widget._extras) {
      this.query = this.widget._extras;
      this.contentForm.controls.query.setValue(this.query.query);
    }
  }

  public getTimelineData(): void {
    if (this.query.hasRules()) {
      this.widgetSettingsService.loader$.next(true);
      this.tagService
        .execute(
          this.initialState,
          this.query,
          this.userService.userPreferences.defaultEcosystemId,
        )
        .then((response: IData) => {
          this.updateNavigationItem();
          this.timelineDataSource = {
            result:
              this.initialState.page > 1
                ? [...this.timelineDataSource.result, ...response.result]
                : response.result,
            count: response.count,
          };
          this.widgetSettingsService.loader$.next(false);
        });
    }
  }

  private updateNavigationItem(): void {
    const query = new Query();
    let rules = this.query.getRules();
    if (this.navigationItem?.rules?.length > 0) {
      rules = this.query.getRules().filter((rule: QueryRule) => {
        return rule.value !== this.navigationItem?.rules[0]?.value;
      });
    }
    rules.forEach((item: QueryRule) => {
      query.addRule(item);
    });
    this.onUpdateWidget({ prop: '_extras', value: query });
  }

  public onUpdateWidget(params: { prop: string; value: any }): void {
    this.widget[params.prop] = params.value;
    this.navigationItem.initialState = cloneDeep(this.initialState);
    this.navigationItem.item[params.prop] = params.value;
  }

  public onToggleFilters(): void {
    this.expandedFilters = !this.expandedFilters;
    this.articleDetail = undefined;
  }

  private createForm(): void {
    this.infoForm = new FormGroup({});
    this.contentForm = new FormGroup({
      query: new FormControl(undefined, [Validators.required]),
    });
    this.metadataForm = new FormGroup({});
    this.form.addControl('alerts', new FormGroup({}));
  }

  private createAlert(
    tagId: string,
  ): Observable<TagThresholdAlert | TagMatchAlert> {
    const alertTypeName = this.alertService.getTypeById(
      this.metadataForm?.get('alertTypeId')?.value,
    )?.label;
    let alertPayload: ICreateTagThresholdAlertDTO | ICreateTagMatchAlertDTO;
    if (alertTypeName === AlertTypes.THRESHOLD) {
      alertPayload = {
        tagId,
        typeId: this.metadataForm?.get('alertTypeId')?.value,
        priority: this.form?.get('alerts.priority')?.value,
        message: this.form?.get('alerts.message')?.value,
        postSlack: this.form?.get('alerts.postSlack')?.value || false,
        emailMe: false, //this.form?.get("alerts.emailMe")?.value || false,
        condition: this.form?.get('alerts.condition')?.value,
        period: this.form?.get('alerts.period')?.value,
        count: this.form?.get('alerts.count')?.value,
      } as ICreateTagThresholdAlertDTO;
    } else {
      alertPayload = {
        tagId,
        typeId: this.metadataForm?.get('alertTypeId')?.value,
        delay: this.form?.get('alerts.delay')?.value,
        priority: this.form?.get('alerts.priority')?.value,
        message: this.form?.get('alerts.message')?.value,
        emailMe: false, //this.form?.get("alerts.emailMe")?.value || false,
        postSlack: this.form?.get('alerts.postSlack')?.value || false,
      } as ICreateTagMatchAlertDTO;
    }
    return this.alertService.create(alertPayload);
  }

  public onSave(createNew?: boolean): void {
    this.widgetSettingsService.loader$.next(true);

    this.tagService
      .save({
        name: this.infoForm.controls.name.value,
        description: this.infoForm.controls.description.value,
        categoryId: this.metadataForm.controls.categoryId?.value,
        ecosystemId: this.userService.userPreferences.defaultEcosystemId,
        query: this.query.modelToDTO(),
        sharedTag: this.metadataForm.controls.sharedTag.value,
        _extras: JSON.stringify({ type: this.query.type }),
      })
      .subscribe(
        (response: Tag) => {
          this.widget.dataSource = [response.tagId];
          let subscriptions: Array<Observable<any>> = [];
          const boardIds = this.metadataForm.controls.boardIds.value?.map(
            (item: Board) => item.id,
          );
          if (boardIds?.length > 0) {
            subscriptions = [...subscriptions, ...this.createWidgets()];
          }
          const alertTypeId = this.metadataForm?.get('alertTypeId')?.value;
          if (alertTypeId) {
            subscriptions.push(this.createAlert(response?.tagId));
          }
          if (subscriptions.length === 0) {
            this.onCreateTagCallback(createNew);
          } else {
            forkJoin(subscriptions).subscribe((response: Array<any>) => {
              this.appendwidgetsToBoards(response, boardIds);
              this.onCreateTagCallback(createNew);
            });
          }
        },
        (error: IError) => {
          if (!error.stopPropagation) {
            this.customOverlayService.openCustom(
              {
                title: error.title,
                message: error.description,
                icon: ['far', 'exclamation-circle'],
                size: '4x',
                dialog: {
                  type: DialogTypes.ALERT,
                },
              },
              DialogComponent,
            );
          }
          this.widgetSettingsService.loader$.next(false);
        },
      );
  }

  private appendwidgetsToBoards(
    observables: Array<any>,
    boardIds: Array<string>,
  ): void {
    const widgetIds = observables.map((item: any) => item?.widgetId);
    boardIds?.forEach((boardId: string, index: number) => {
      this.boardService
        .addWidgetsToBoard(boardId, {
          add: [widgetIds[index]],
        })
        .subscribe();
    });
  }

  private createWidgets(): Array<Observable<any>> {
    const boardIds = this.metadataForm.controls.boardIds.value?.map(
      (item: Board) => item.id,
    );
    if (boardIds?.length > 0) {
      let observables = [];
      boardIds.forEach(() => {
        observables.push(
          this.widgetService.createWidget({
            dataSource: this.widget.dataSource,
            description: this.infoForm.controls.description.value,
            ecosystemId: this.userService.userPreferences.defaultEcosystemId,
            name: this.infoForm.controls.name.value,
            type: AnalysisTypes.TIMELINE,
            chartType: ChartType.TIMELINE,
            width: 30,
            x: 0,
            height: 12,
            y: 0,
          }),
        );
      });
      return observables;
    } else {
      return [of()];
    }
  }

  private onCreateTagCallback(createNew: boolean): void {
    this.snackBar.open(
      'Your tag has been created, and is available for immediate use!',
      'Close',
      {
        horizontalPosition: 'right',
        duration: 5000,
        verticalPosition: 'top',
      },
    );

    if (createNew) {
      this.onReset();
    } else {
      this.customOverlayRef.close({ refresh: true });
    }
    this.widgetSettingsService.loader$.next(false);
  }

  private getChangedAlertsProperties(): { [key: string]: any } {
    let changedProperties = {};
    Object.keys((this.form.get('alerts') as FormGroup).controls).forEach(
      (name: string) => {
        const currentControl = (this.form.get('alerts') as FormGroup).controls[
          name
        ];

        if (!currentControl.pristine) {
          changedProperties = {
            ...changedProperties,
            [name]: currentControl.value,
          };
        }
      },
    );

    if (this.keycloakService.isUserInRole('saas-alerts')) {
      const currentControl = this.metadataForm.controls['alertTypeId'];

      if (!currentControl.pristine) {
        changedProperties = {
          ...changedProperties,
          typeId: currentControl.value,
        };
      }
    }
    return changedProperties;
  }

  private getChangedTagProperties(): { [key: string]: any } {
    let changedProperties = {};

    Object.keys(this.infoForm.controls).forEach((name: string) => {
      const currentControl = this.infoForm.controls[name];

      if (!currentControl.pristine) {
        if (name === 'searchType') {
          changedProperties = {
            ...changedProperties,
            _extras: JSON.stringify({ type: currentControl.value }),
          };
        } else {
          changedProperties = {
            ...changedProperties,
            [name]: currentControl.value,
          };
        }
      }
    });

    Object.keys(this.metadataForm.controls).forEach((name: string) => {
      const currentControl = this.metadataForm.controls[name];

      if (
        !currentControl.pristine &&
        name !== 'boardIds' &&
        name !== 'alertTypeId'
      ) {
        changedProperties = {
          ...changedProperties,
          [name]: currentControl.value,
        };
      }
    });

    Object.keys(this.contentForm.controls).forEach((name: string) => {
      const currentControl = this.contentForm.controls[name];

      if (currentControl.touched) {
        delete currentControl.value.selections;
        changedProperties = {
          ...changedProperties,
          [name]: currentControl.value.modelToDTO(),
        };
      }
    });

    return changedProperties;
  }

  private updateTag(): Observable<Tag> {
    const changedProperties = this.getChangedTagProperties();
    if (Object.keys(changedProperties)?.length > 0) {
      changedProperties.tagId = this.widget.dataSource[0];
      if (this.widget.categoryId) {
        changedProperties.systemTag = true;
        changedProperties.categoryId = this.widget.categoryId;
      } else {
        changedProperties.systemTag = false;
      }
      return this.tagService.update(changedProperties);
    }
    return undefined;
  }

  public onEdit(): void {
    const observables: Array<Observable<any>> = [
      this.updateTag(),
      ...this.updateBoards(),
      this.updateAlerts(),
    ].filter(Boolean);
    if (observables.length > 0) {
      forkJoin(observables).subscribe((values: Array<any>) => {
        const changedProperties = this.getChangedTagProperties();
        if (Object.keys(changedProperties)?.length > 0) {
          this.snackBar.open('Your tag has been updated!', 'Close', {
            horizontalPosition: 'right',
            duration: 5000,
            verticalPosition: 'top',
          });
          const tag = values[0] as Tag;
          if (tag) {
            this.customOverlayRef.close({
              refresh: true,
              widget: this.widgetService.mergeTagIntoTimeline(tag, this.widget),
            });
          }
        } else {
          this.widget.alertTypeId = this.metadataForm.get('alertTypeId')?.value;
          this.customOverlayRef.close({
            refresh: true,
            widget: this.widget,
          });
        }
      });
    }
  }

  private updateAlerts(): Observable<any> {
    const changedAlertsProperties = this.getChangedAlertsProperties();
    const hasAlertTypeChanged = Object.keys(changedAlertsProperties).includes(
      'typeId',
    );
    let result: Observable<any>;
    if (hasAlertTypeChanged && changedAlertsProperties.typeId === undefined) {
      result = this.alertService.delete(this.alert.id);
    } else {
      if (Object.keys(changedAlertsProperties)?.length > 0) {
        if (this.alert?.id) {
          if (hasAlertTypeChanged) {
            result = concat(
              this.alertService.delete(this.alert.id),
              this.createAlert(this.widget.dataSource[0]),
            );
          } else {
            result = this.alertService.update(
              this.alert.id,
              changedAlertsProperties,
            );
          }
        } else {
          result = this.createAlert(this.widget.dataSource[0]);
        }
      } else {
        result = of(undefined);
      }
    }
    return result;
  }

  /**
   * Updates the boards by adding or deleting the widget's tag ID.
   * @returns An array of observables representing the update operations.
   */
  private updateBoards(): Array<Observable<IBoard>> {
    let updateWidgetsPromises: Array<Observable<IBoard>> = [];
    const payload = this.getBoardIdsPayload();
    payload.add?.forEach((boardId: string) => {
      this.widgetService
        .createWidget({
          dataSource: [this.widget.dataSource],
          description: this.infoForm.controls.description.value,
          ecosystemId: this.infoForm.controls.ecosystemId.value,
          name: this.infoForm.controls.name.value,
          type: AnalysisTypes.TIMELINE,
          chartType: ChartType.TIMELINE,
          width: 30,
          x: 0,
          height: 12,
          y: 0,
        })
        .subscribe((widget: Timeline) => {
          this.boardService
            .addWidgetsToBoard(boardId, {
              add: [widget.widgetId],
            })
            .subscribe();
        });
    });

    payload.delete?.forEach((boardId: string) => {
      if (this.boardService.board.id === boardId) {
        this.boardService.removeTimelines$.next(this.widget);
      } else {
        updateWidgetsPromises.push(
          this.boardService.addWidgetsToBoard(boardId, {
            delete: [this.widget.widgetId],
          }),
        );
      }
    });
    return updateWidgetsPromises;
  }

  /**
   * Retrieves the payload containing the board IDs to be added and deleted.
   * @returns The payload object with the "add" and "delete" arrays.
   */
  private getBoardIdsPayload(): { add: string[]; delete: string[] } {
    const payload = { add: [], delete: [] };
    const boardIds = this.metadataForm.controls.boardIds.value?.map(
      (item: Board) => item.id,
    );
    this.boardIds.forEach((boardId: string) => {
      if (!boardIds?.includes(boardId)) {
        payload.delete.push(boardId);
      }
    });

    boardIds?.forEach((boardId: string) => {
      if (!this.boardIds.includes(boardId)) {
        payload.add.push(boardId);
      }
    });

    return payload;
  }

  private onReset(): void {
    this.articleDetail = undefined;
    this.widget = new Timeline(
      undefined,
      undefined,
      AnalysisTypes.TIMELINE,
      undefined,
      undefined,
      ChartType.TIMELINE,
      this.userService.userPreferences.defaultEcosystemId,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
    );
    this.timelineDataSource = undefined;
    this.stepper.reset();
    this.infoForm.reset();
    this.contentForm.reset();
    this.metadataForm.reset();
    this.contentForm.reset();
    this.query = new Query();
    this.tagService.resetQuery$.next();
    this.infoForm.controls.searchType.setValue(QueryType.QUICK_SEARCH);
    if (this.boardService?.board?.id) {
      const board = this.boardService?.findBoardById(
        this.boardService?.board?.id,
      );
      this.metadataForm.controls.boardIds.setValue([board]);
    }
  }

  public onDataBound(query: Query): void {
    this.articleDetail = undefined;
    this.query = query;
    this.cdr.detectChanges();
    this.tagService.reloadFilters$.next({
      queryFilters: new QueryFilters(
        100,
        1,
        this.initialState.where,
        undefined,
        undefined,
      ),
      query: this.query,
    });
    if (this.query.hasRules()) {
      this.contentForm.controls.query.setValue(this.query);
    } else {
      this.contentForm.controls.query.setValue(undefined);
    }
    this.getTimelineData();
  }

  public onSearch(params: QueryFilters): void {
    this.initialState = params;
    this.getTimelineData();
  }

  public onChangeQueryType(): void {
    if (this.query.hasRules()) {
      this.customOverlayService.openCustom(
        {
          title: 'Change search type',
          message:
            'In order to change your search type, you need to reset your query.<br><br>Do you wish to continue?',
          icon: ['far', 'question-circle'],
          size: '4x',
          dialog: {
            type: DialogTypes.CONFIRM,
          },
        },
        DialogComponent,
        (result: boolean) => {
          if (result === true) {
            this.clearQuery();
            this.query.type = this.infoForm.controls.searchType.value;
            this.getFilters();
          } else {
            this.infoForm.controls.searchType.setValue(this.query.type);
          }
        },
      );
    } else {
      this.clearQuery();
      this.query.type = this.infoForm.controls.searchType.value;
      this.getFilters();
    }
  }

  private clearQuery(): void {
    if (this.infoForm.controls.searchType.value === QueryType.QUICK_SEARCH) {
      this.query = new Query([], this.infoForm.controls.searchType.value);
    }
    if (this.infoForm.controls.searchType.value === QueryType.QUERY_BUILDER) {
      this.query = new Query(
        [
          new QueryRule(
            DTOQueryFieldType.content,
            DTOQueryConditionOperator.contains,
            [],
          ),
        ],
        this.infoForm.controls.searchType.value,
      );
    } else {
      this.query = new Query([], this.infoForm.controls.searchType.value);
    }
    this.contentForm.controls.query.setValue(undefined);
  }

  private getFilters(): void {
    this.categoryService
      .getCategories(this.userService.userPreferences.defaultEcosystemId)
      .then((response: Array<Category>) => {
        this.categoriesDataSource = response;
        this.loadSelections();
        this.cdr.detectChanges();
        this.tagService.getSearchResults$.next();
        this.loadFilters();
      });
  }

  private loadFilters(): void {
    this.tagService.reloadFilters$.next({
      queryFilters: new QueryFilters(
        100,
        1,
        this.initialState.where,
        undefined,
        undefined,
      ),
      query: this.query,
    });
  }
}
