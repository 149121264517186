import { TemplateRef } from '@angular/core';
import { IsTagEnabledPipe, TableColumn } from '@intorqa-ui/core';
import { cloneDeep } from 'lodash';
import { AnalysisTypes, ChartType } from '../enums/widget.enum';
import { IDisplayType, ITimeline } from '../interfaces/widget.interface';
import { Widget } from '@portal/boards/models/widget';
import { TIMELINE_CHART_TYPES } from '@portal/boards/const/timeline.const';

export class Timeline extends Widget implements ITimeline {
  constructor(
    public widgetId: string,
    public username: string,
    public type: AnalysisTypes,
    public name: string,
    public description: string,
    public chartType: ChartType,
    public ecosystemId: string,
    public width: number,
    public height: number,
    public x: number,
    public y: number,
    public dataSource: Array<string>,
    public createdDate: number,
    public sharedTag: boolean,
    public _extras: any,
    public categoryId: string,
    public lastTaggingTime: number,
    public updatedDate: number,
    public alertTypeId: string,
    public options: { [key: string]: any },
    public dataPoints: Array<string>,
  ) {
    super(
      widgetId,
      username,
      type,
      name,
      description,
      chartType,
      ecosystemId,
      updatedDate,
    );
  }

  public hasOptions(): boolean {
    return this.dataSource?.length > 0;
  }

  public hasMetrics(): boolean {
    return this.dataSource?.length > 0;
  }

  public getTableColumns(template: TemplateRef<unknown>): Array<TableColumn> {
    return [
      {
        name: undefined,
        dataKey: 'result',
        isSortable: true,
        customRender: true,
        template,
      },
    ];
  }

  public getDisplayTypes(): Array<IDisplayType> {
    return TIMELINE_CHART_TYPES;
  }

  public getSelectedType(): IDisplayType {
    if (TIMELINE_CHART_TYPES?.length > 0) {
      const widget = cloneDeep(this);
      widget.chartType = this.chartType;
      if (widget.chartType === ChartType.TIMELINE) {
        return TIMELINE_CHART_TYPES.find((item: IDisplayType) => {
          return item.type === widget.chartType;
        });
      } else {
        return TIMELINE_CHART_TYPES.find((item: IDisplayType) => {
          return item.id === widget.dataPoints[0];
        });
      }
    }
  }

  public getRuleValue(): Array<string> {
    return this.dataSource || [this.widgetId];
  }

  public isEnabled(isTagEnabledPipe: IsTagEnabledPipe): boolean {
    return isTagEnabledPipe.transform(this);
  }

  public cloneDeep(): Timeline {
    return new Timeline(
      this.widgetId,
      this.username,
      this.type,
      this.name,
      this.description,
      this.chartType,
      this.ecosystemId,
      this.width,
      this.height,
      this.x,
      this.y,
      this.dataSource,
      this.createdDate,
      this.sharedTag,
      this._extras,
      this.categoryId,
      this.lastTaggingTime,
      this.updatedDate,
      this.alertTypeId,
      this.options,
      this.dataPoints,
    );
  }
}
