<div class="query__container">
  <span
    [ngClass]="{ label: true, 'font-semibold': true, 'mb-1': true, required }"
  >
    Please choose a {{ type === TagCategory.Actor ? 'actor' : 'channel' }}:
  </span>
  <itq-list-search
    class="relative"
    [initialState]="initialState"
    [query]="query"
    [showSelectionsLabel]="false"
    [dataSource]="
      dataSource
        | filterSelections
          : query
          : (timeline.dataSource && timeline.dataSource?.length > 0
              ? timeline.dataSource[0]
              : '')
    "
    (changeValue)="onRemoveTag($event)"
    (search)="onGetTags($event)"
  >
    @for (
      item of dataSource
        | filterSelections
          : query
          : (timeline.dataSource && timeline.dataSource?.length > 0
              ? timeline.dataSource[0]
              : '');
      track item
    ) {
      <itq-tags-include-selection
        [item]="item"
        (changeValue)="onAddTag($event)"
      ></itq-tags-include-selection>
    }
  </itq-list-search>
</div>
<div class="selections__container" *ngIf="query?.selections?.length > 0">
  <itq-nav-button
    [openDirection]="{ vertical: 'up', horizontal: 'right' }"
    [openPosition]="'outside'"
    [materialStyle]="'basic'"
    [width]="700"
    [template]="selectionsTemplate"
  >
    <fa-icon [icon]="['far', 'filter']"></fa-icon>
    View query ({{ query.selections.length }} )</itq-nav-button
  >
  <itq-mat-button
    [materialStyle]="'basic'"
    [type]="'button'"
    (clickEvent)="onClearFilters()"
  >
    <fa-icon [icon]="['far', 'trash-alt']"></fa-icon>
    Clear query
  </itq-mat-button>
</div>
<itq-loader *ngIf="showLoader"></itq-loader>

<ng-template #selectionsTemplate>
  <itq-timeline-selections
    [query]="query"
    (removeTag)="onRemoveTag($event)"
  ></itq-timeline-selections>
</ng-template>
