import { AbstractControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

export class CustomErrorStateMacther implements ErrorStateMatcher {
  isErrorState(
    control: AbstractControl<any, any>,
    form: FormGroupDirective | NgForm,
  ): boolean {
    return (
      form.form.controls.start &&
      form.form.controls.end &&
      form.form.controls.start.value <= form.form.controls.end.value
    );
  }
}
